import React from "react";
import "./index.scss";
import { Button, Popover, Tooltip, InputNumber, Radio, Modal, Select } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useStore } from "../../../common/store/index";
import { useObserver } from "mobx-react-lite";
import { useLocalStore } from "mobx-react";
interface IComponentProps {
  children: JSX.Element;
}
export const SettingsPopover = (props: IComponentProps) => {
  const { setting, lang } = useStore();

  const store = useLocalStore(() => ({
    toggleExpert() {
      if (setting.data.userExpertMode == true) {
        setting.data.userExpertMode = false;
      } else {
        Modal.info({
          title: lang.t("confirm.expert.sure"),
          content: (
            <div>
              <div>{lang.t("confirm.expert.content1")}</div>
              <div className="mt-6 font-semibold">{lang.t("confirm.expert.content2")}</div>
            </div>
          ),
          okText: lang.t("confirm.expert.button"),
          onOk() {
            setting.data.userExpertMode = true;
          },
        });
      }
    },
  }));

  const text = null;
  const content = useObserver(() => (
    <div className="component__settings_popover__content">
      <div className="c-gray mb-2 font-light text-base">
        <b>{lang.t("transaction_settings")}</b>
      </div>
      <div className="flex items-center c-gray-10 mb-2 font-light">{lang.t("slippage_tolerance")}</div>
      <div className="flex justify-center items-center component__settings_popover__content__tolerance__row ml-2">
        {setting.SlippageTolerance.map((v, i) => (
          <Button key={i} onClick={(e) => setting.setUserSlippageTolerance(v)} className={`settings__btn w-12 ${v === setting.data.userSlippageTolerance ? "bg-third c-white" : "bg-white-10 c-gray"}`}>
            {v / 100}%
          </Button>
        ))}
        <InputNumber
          className="flex-1 border-white-20 c-gray-10 text-right"
          onChange={(v) => setting.setUserSlippageTolerance(Number(v) * 100)}
          onBlur={(v) => setting.save()}
          value={setting.state.userSlippageTolerance}
          min={0}
          max={10000}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>
      {setting.settingStatus.error && <div className="flex items-center text-red-600 mb-2 font-light">{setting.settingStatus.error}</div>}
      {setting.settingStatus.warning && <div className="flex items-center text-yellow-600 mb-2 font-light">{setting.settingStatus.warning}</div>}

      <p className="flex items-center c-gray-10 mt-3 font-light">
        {lang.t("transaction_deadline")}
        <Tooltip placement="left" title={lang.t("transaction_deadline_tips")} trigger="hover">
          <QuestionCircleOutlined className="ml-2" />
        </Tooltip>
      </p>
      <div className="flex items-center c-gray font-light">
        <InputNumber
          className="component__settings_popover__content__transaction_deadline c-gray-10 border-white-20"
          placeholder="1"
          value={setting.state.userDeadline}
          onChange={(v) => setting.setDeadLine(v)}
        />
        &nbsp;{lang.t("minutes")}
      </div>
      <div className="c-gray mt-2 mb-2 font-light text-base">
        <b>{lang.t("interface_settings")}</b>
      </div>
      <div className="flex items-center justify-between c-gray font-light">
        <div className="flex items-center">
          {lang.t("toggle_expert_mode")}
          <Tooltip placement="left" title={lang.t("toggle_expert_mode_tips")} trigger="hover">
            <QuestionCircleOutlined className="c-gray-10 ml-2" />
          </Tooltip>
        </div>
        <Radio.Group className="component__settings_popover__content__expert__radio_group" value={setting.data.userExpertMode}>
          <Radio.Button value={true} onClick={(e) => store.toggleExpert()}>
            {lang.t("on")}
          </Radio.Button>
          <Radio.Button value={false} className="test-radio" onClick={(e) => store.toggleExpert()}>
            {lang.t("off")}
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="flex items-center justify-between c-gray font-light mt-2">
        <div className="flex items-center">{lang.t("toggle_approve_all")}</div>
        <Radio.Group className="component__settings_popover__content__expert__radio_group" value={setting.data.approveAll}>
          <Radio.Button value={true} onClick={(e) => setting.set("approveAll", true)}>
            {lang.t("on")}
          </Radio.Button>
          <Radio.Button value={false} onClick={(e) => setting.set("approveAll", false)}>
            {lang.t("off")}
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="flex items-center justify-between c-gray font-light mt-2">
        <div className="flex items-center">{lang.t("language")}</div>
        <Select value={lang.lang} onChange={lang.setLang}>
          {lang.supportedLanguages.map((i) => {
            return (
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  ));
  return (
    <Popover placement="bottomRight" title={text} content={content} trigger="click">
      {props.children}
    </Popover>
  );
};
