import { Popover } from "antd";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../common/store/index";
import { Link } from "react-router-dom";
import "./more-popover.component.scss";

export const MorePopover = (props: { children: JSX.Element }) => {
  const { lang } = useStore();
  const gallery = [
    { title: lang.t("more.about"), link: "https://mimo.finance/", src: "/image/icon_about.png" },
    { title: lang.t("more.docs"), link: "https://docs.mimo.finance/", src: "/image/icon_docs.png" },
    { title: lang.t("more.github"), link: "https://github.com/mimoprotocol", src: "/image/icon_github.png" },
    { title: lang.t("more.telegram"), link: "https://t.me/mimoprotocol", src: "/image/icon_telegram.png" },
    { title: lang.t("more.analytics"), link: "https://v1.info.mimo.exchange/", src: "/image/icon_analytics.png" }
  ];
  const text = null;
  const content = useObserver(() => {
    return (
      <div className="options_wrapper">
        {gallery.map((item, index) => {
          return (
            <a key={index} onClick={() => (window.location.href = item.link)} className="options_wrapper--item flex items-center">
              <span className={`inline-block menu-item-icon menu-item-icon${index}`}></span>
              <span>{item.title}</span>
            </a>
          );
        })}
      </div>
    );
  });
  return (
    <Popover placement="bottomRight" title={text} content={content} trigger="click">
      {props.children}
    </Popover>
  );
};
