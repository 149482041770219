import React, { useState } from "react";
import "./index.scss";
import { Input } from "antd";
import { useStore } from "../../../common/store";

interface IComponentProps {
  label: string;
  address: string;
  onChange: Function;
  placeholder: string;
}

export const AddressInputField = (props: IComponentProps) => {
  const { label, address, onChange, placeholder } = props;
  const { lang } = useStore();

  return (
    <div className="component__address_input_field my-2">
      <div className="c-grey1">{label}</div>
      <Input
        className="mt-2 c-grey font-medium"
        value={address}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};
