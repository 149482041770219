import { TokenTradeMetadata } from "../../type";
import numeral from "numeral";
import BN from "bignumber.js";
import { Exchange } from "../../../generated/gql/schema";

export const helper = {
  promise: {
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async runAsync<T, U = Error>(promise: Promise<T>): Promise<[U | null, T | null]> {
      return promise
        .then<[null, T]>((data: T) => [null, data])
        .catch<[U, null]>((err) => [err, null]);
    },
  },
  string: {
    truncate(fullStr = "", strLen, separator = "") {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || "...";

      const sepLen = separator.length;
      const charsToShow = strLen - sepLen;
      const frontChars = Math.ceil(charsToShow / 2);
      const backChars = Math.floor(charsToShow / 2);

      return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
    },
    formatBN(value: BN | string | number, flexFormat?: boolean) {
      let bn: BN | number = typeof value === "string" ? new BN(value) : value;
      let text;
      let decimals = 3;
      if (bn instanceof BN) {
        if (bn.isNaN()) return "-";
        if (bn.isZero()) return "0";
        if (flexFormat) {
          if (bn.abs().gte(100)) decimals = 1;
          else if (bn.abs().gte(10)) decimals = 2;
          text = bn.toFixed(decimals);
        } else {
          text = helper.number.numberWithThousandsSeparators(bn.toNumber());
        }
      } else {
        if (flexFormat) {
          if (Math.abs(bn) >= 100) decimals = 1;
          else if (Math.abs(bn) >= 10) decimals = 2;
          text = bn.toFixed(decimals);
        } else {
          text = helper.number.numberWithThousandsSeparators(bn);
        }
      }
      if (!flexFormat && Number(text) === 0) return bn.toFixed(10);
      return text;
    },
  },
  number: {
    countNonZeroNumbers: (str: string) => {
      let index = 0;
      length = str.length;
      for (; index < length && (str[index] === "0" || str[index] === "."); index += 1);
      return length - index - Number(str.includes("."));
    },
    toPrecisionFloor: (str: number | string, options?: { decimals?: number; format?: string }) => {
      const { decimals = 6, format = "" } = options || {};
      if (!str || isNaN(Number(str))) return "";

      if (helper.number.countNonZeroNumbers(String(str)) <= decimals) return String(str);
      const numStr = new BN(str).toFixed();
      let result = "";
      let index = 0;
      const numLength = numStr.length;

      for (; numStr[index] === "0" && index < numLength; index += 1);

      if (index === numLength) return "0";

      if (numStr[index] === ".") {
        // number < 0
        result = "0";
        for (; (numStr[index] === "0" || numStr[index] === ".") && index < numLength; index += 1) {
          result = result + numStr[index];
        }
      }
      let resultNumLength = 0;
      for (; index < numLength && (resultNumLength < decimals || !result.includes(".")); index += 1) {
        result = result + numStr[index];

        if (numStr[index] !== ".") resultNumLength += 1;
      }
      if (format) {
        return numeral(Number(result)).format(format);
      }

      return new BN(result).toFixed();
    },
    getBN: (value: number | string | BN) => {
      return value instanceof BN ? value : typeof value === "string" ? new BN(Number(value)) : new BN(value);
    },
    numberWithThousandsSeparators(n: number) {
      const nf = new Intl.NumberFormat();
      return nf.format(n);
    },
  },
  tokenData: {
    checkIsIotx(input: Partial<TokenTradeMetadata>) {
      return input.symbol == "IOTX";
    },
    checkTokenStatusType(token: Partial<TokenTradeMetadata>) {
      if (token.state.saved) {
        return "added_by_uesr";
      } else {
        if (token.state.warning) {
          return "self_listed";
        }
        if (token.state.external) {
          return "found_by_address";
        }
        return "normal";
      }
    },
  },
  exchange: {
    isValidExchange(address: string) {
      return address !== "io1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqd39ym7";
    },
    compareLiquidityFn(a: TokenTradeMetadata, b: TokenTradeMetadata) {
      if (b.address === "iotx") return 1;
      if (a.address === "iotx") return -1;
      if (a.pool?.exchangeIotxBalance && b.pool?.exchangeIotxBalance) {
        return new BN(b.pool?.exchangeIotxBalance).minus(new BN(a.pool?.exchangeIotxBalance)).toNumber();
      }
      if (a.pool?.exchangeIotxBalance) {
        return -1;
      }
      if (b.pool?.exchangeIotxBalance) {
        return 1;
      }
      return 0;
    },
    calculateAPR(item: Exchange | undefined) {
      if (!item) return "-";
      let APROfPool: string;
      const liquidity = new BN(item.balanceOfIOTX).multipliedBy(2);
      if (liquidity.isNaN() || liquidity.isZero()) {
        APROfPool = "";
      } else {
        APROfPool = helper.string.formatBN(new BN(item.volumeInPast24Hours).multipliedBy(0.003).multipliedBy(365).div(liquidity).multipliedBy(100).toNumber(), true);
      }
      return APROfPool;
    },
  },
  img: {
    defaultImgUrl() {
      return "/image/token_custom.png";
    },
    assetsImageUrl(img) {
      return `https://githubproxy.b-cdn.net/iotexproject/iotex-token-metadata/master/images/${img}.png`;
    },
  },
};
