import { Modal } from "antd";
import { fromRau } from "iotex-antenna/lib/account/utils";
import { action, computed, observable } from "mobx";
import remotedev from "mobx-remotedev";
import { AntennaUtils } from "../utils/antanna";
import { eventBus } from "../utils/eventBus";
import { utils } from "../utils/index";
import { rootStore } from "./index";
import { Connector } from "../../type";
import { hooks } from "../../client/utils/hooks";
import toast from "react-hot-toast";

// @remotedev({ name: "wallet" })
export class WalletStore {
  @observable
  account = {
    address: "",
    balance: "",
    balanceRaw: ""
  };
  @observable
  private isConnectWsError = false;

  @observable
  showAccountChangeModal = false;

  @observable
  screenIsBlur = false;

  @observable showConnector = false;

  @computed
  get state() {
    return {
      balance: utils.helper.number.toPrecisionFloor(this.account.balance)
    };
  }

  @action.bound
  toggleAccountChangeModal() {
    this.showAccountChangeModal = !this.showAccountChangeModal;
  }

  @action.bound
  setShowConnector(val) {
    this.showConnector = val;
  }

  @action.bound
  async init() {
    this.initEvent();
    this.initAntenna();
    window.onblur = () => {
      this.screenIsBlur = true;
    };
  }

  // async reset() {
  //   utils.eventBus.removeAllListeners("client.iopay.close").removeAllListeners("client.iopay.connected");
  // }

  initEvent() {
    utils.eventBus
      .on("client.iopay.connected", () => {
        console.log("iopay-desktop connected.");
        this.isConnectWsError = false;
      })
      .on("client.iopay.close", () => {
        this.account = { address: "", balance: "", balanceRaw: "" };
      })
      .on("client.iopay.connectError", () => {
        this.account = { address: "", balance: "", balanceRaw: "" };
        this.isConnectWsError = true;
      });
  }

  @action.bound
  handleConnectWallet() {
    this.initAntenna();
    if (!this.account.address || this.isConnectWsError) {
      const title = rootStore.lang.t(utils.env.isMobile() ? (utils.env.isIoPayMobile() ? "tips.connect_fail.iopay" : "tips.connect_fail.mobile") : "tips.connect_fail");
      const modal = Modal.warning({
        onOk: () => {
          utils.env.isMobile() && !utils.env.isIoPayMobile() ? this.ioPayIsInstall() : modal.destroy();
        },
        okText: rootStore.lang.t(utils.env.isMobile() && !utils.env.isIoPayMobile() ? "open.iopay.button" : "ok"),
        title
      });
      if (!utils.env.isMobile() || utils.env.isIoPayMobile()) {
        setTimeout(() => {
          modal.destroy();
        }, 5000);
      }
    }
  }

  @action.bound
  connectWallet() {
    if (utils.env.isIoPayMobile()) {
      this.handleConnectWallet();
    } else {
      this.setShowConnector(true);
    }
  }

  ioPayIsInstall = () => {
    this.openDeepLink();
    setTimeout(() => {
      if (!this.screenIsBlur) {
        location.href = "https://iopay.iotex.io";
      }
    }, 3000);
  };

  openDeepLink = () => {
    const a = document.createElement("a");
    const tagId = "startIoPay";
    a.setAttribute("href", "iopay://io.iotex.iopay/open?action=web&url=" + window.location.href);
    a.setAttribute("id", tagId);
    if (document.getElementById(tagId)) {
      // @ts-ignore
      document.body.removeChild(document.getElementById(tagId));
    }
    document.body.appendChild(a);
    a.click();
  };

  async activeConnector(connector: Connector) {
    if (AntennaUtils.antenna) {
      delete AntennaUtils.antenna;
      delete AntennaUtils.connector;
    }
    localStorage.setItem("connector", connector);
    this.initAntenna();

    toast.promise(
      hooks.waitAccount(5000),
      {
        loading: rootStore.lang.t("connector.loading"),
        success: rootStore.lang.t("connector.success"),
        error: rootStore.lang.t("connector.failed")
      },
      { id: "connector" }
    );
  }

  @action.bound
  async initAntenna() {
    if (!AntennaUtils.getAntenna().iotx.accounts?.length) {
      return setTimeout(() => {
        this.initAntenna();
      }, 500);
    }

    this.account.address = AntennaUtils.getAntenna().iotx.accounts[0].address;
    this.loadAccount();
    setTimeout(() => {
      eventBus.emit("client.wallet.onAccount");
    }, 500);
  }

  @action.bound
  async loadAccount() {
    if (!this.account.address) return;
    const data = await AntennaUtils.getAntenna().iotx.getAccount({ address: this.account.address });
    if (data?.accountMeta) {
      const { balance } = data?.accountMeta;
      this.account.balance = fromRau(balance, "iotx");
      this.account.balanceRaw = balance;
      eventBus.emit("client.wallet.iotx.onBalance");
    }
  }
}
