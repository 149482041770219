import React, { useEffect } from "react";
import "./index.scss";
import { Button, Avatar, Slider, Tooltip } from "antd";
import { ArrowLeftOutlined, QuestionCircleOutlined, PlusOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { TokenInputField } from "../../components/TokenInputField";
import { useHistory } from "react-router-dom";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useStore } from "../../../common/store/index";
import { TokenMetadata } from "../../../type";
import { RemoveLiquidityConfirmModal } from "./components/RemoveLiquidityConfirmModal";
import { RemoveLiquiditySubmittedModal } from "./components/RemoveLiquiditySubmittedModal";
import { useRouter } from "../../utils/react-hooks";
import { AppLayout } from "../../layouts/AppLayout/index";
import { hooks } from "../../utils/hooks";
import { TokenAvatar } from "../../components/TokenAvatar/index";

export const LiquidityRemove = () => {
  const { wallet, token: tokenStore, lang, removeLiquidity } = useStore();
  const router = useRouter();

  const store = useLocalStore(() => ({
    async selectToken(key: "source" | "dest", val: Partial<TokenMetadata>) {
      await tokenStore.selectToken({ address: val.address });
      if (key === "dest") {
        const token = tokenStore.tokens[val.address];
        tokenStore.loadTokenPool(token);
      }

      if (removeLiquidity[key]) {
        removeLiquidity[key].token = val.address;
      }
    },
    onConfirm() {
      removeLiquidity.status = "confirm";
    },
    onBack() {
      router.history.push("/pool");
    },
    approve(token: string) {},
  }));
  useEffect(() => {
    hooks.waitAccount().then(() => {
      store.selectToken("source", { address: router.query.source || "iotx" });
      if (router.query.dest) {
        store.selectToken("dest", { address: router.query.dest });
      }
    });
    return () => {
      removeLiquidity.reset();
    };
  }, []);

  return useObserver(() => (
    <AppLayout>
      <div className="page__liquidity_remove app-frame-content ">
        <div className="page__liquidity_remove__content app-box-shadow app-frame bg-white p-3 rounded-lg">
          <div className="page__liquidity_remove__content__header flex justify-between items-center my-2 c-gray-20">
            <Button type="text" shape="circle" icon={<ArrowLeftOutlined className="c-gray-10 cursor-pointer" />} onClick={store.onBack} />
            <span className="text-xl font-medium">{lang.t("remove_liquidity")}</span>
            <Tooltip placement="leftTop" title={lang.t("remove_liquidity_help")}>
              <Button type="text" shape="circle" icon={<QuestionCircleOutlined className="c-gray-10" />} />
            </Tooltip>
          </div>
          <div className="page__liquidity_remove__content__main mt-4">
            <div className="bg-white-30 px-4 py-3 rounded">
              <div className="flex justify-between items-center font-semibold">
                <div className="text-sm c-gray">{lang.t("amount")}</div>
                {false && <div className="text-sm c-third">{lang.t("detailed")}</div>}
              </div>
              <div className="c-gray-30 text-4xl font-semibold">{`${removeLiquidity.percent}%`}</div>
              <div className="mb-8">
                <Slider className="page__liquidity_remove__content__main__slider" min={0} max={100} value={removeLiquidity.percent} onChange={removeLiquidity.setPercent} />
              </div>
              <div>
                <div className="flex justify-between items-center mt-4">
                  {[0, 25, 50, 75, 100].map((percent) => (
                    <div className="text-base w-16 cursor-pointer c-third bg-white text-center py-1" key={percent} onClick={(e) => removeLiquidity.setPercent(percent)}>
                      {percent === 100 ? "MAX" : percent === 0 ? 0 : `${percent}%`}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-3 mb-1">
              <ArrowDownOutlined className="c-gray w-6" />
            </div>
            <div className="bg-white-30 px-4 py-3 rounded">
              <div className="flex justify-between items-center mb-3">
                <div className="text-2xl font-semibold">{removeLiquidity.sourceTokenData.amount || "-"}</div>
                <div className="flex items-center text-xl font-semibold">
                  <TokenAvatar className="avatar-width" src={removeLiquidity.sourceTokenData.logoURI} />
                  &nbsp;{removeLiquidity.sourceTokenData.symbol}
                </div>
              </div>
              <div className="flex justify-between items-center mb-3">
                <div className="text-2xl font-semibold">{removeLiquidity.destTokenData.amount || "-"}</div>
                <div className="flex items-center text-xl font-semibold">
                  <TokenAvatar className="avatar-width" src={removeLiquidity.destTokenData.logoURI} />
                  &nbsp;{removeLiquidity.destTokenData.symbol}
                </div>
              </div>
              {false && (
                <div className="text-right">
                  <span className="c-third text-base font-semibold">{`Receive ${removeLiquidity.destTokenData.symbol}`}</span>
                </div>
              )}
            </div>
            <div className="c-gray-10 flex justify-between text-sm font-medium mt-1">
              <div>{lang.t("price")}</div>
              <div>
                <div>
                  1 {removeLiquidity.sourceTokenData?.symbol} = {removeLiquidity.sourceDestRate} {removeLiquidity.destTokenData?.symbol}
                </div>
                <div>
                  1 {removeLiquidity.destTokenData?.symbol} = {removeLiquidity.destSourceRate} {removeLiquidity.sourceTokenData?.symbol}
                </div>
              </div>
            </div>
            {false && (
              <Button onClick={store.onConfirm} type="primary" className="page__liquidity_remove__content__main__suply w-full mt-4" size="large">
                {lang.t("approve")}
              </Button>
            )}
            <Button onClick={store.onConfirm} type="primary" className="page__liquidity_remove__content__main__suply w-full mt-4" size="large">
              {lang.t("remove")}
            </Button>
          </div>

          {removeLiquidity.dest.token && (
            <div className="page__liquidity_remove__content__position app-box-shadow  bg-white rounded p-3 px-5 mb-4">
              <div className="c-gray-20 text-sm">{lang.t("your_position")}</div>
              <div className="flex items-center text-lg justify-between mt-2 font-semibold">
                <div className="flex items-center">
                  <Avatar.Group maxCount={2} className="page__liquidity_remove__content__position__avatar_group">
                    <Avatar className="avatar-width" src={"/image/iotx.png"} />
                    <TokenAvatar className="avatar-width" src={removeLiquidity.destTokenData.logoURI} />
                  </Avatar.Group>
                  &nbsp;
                  <span>{removeLiquidity.destTokenData.symbol}/IOTX</span>
                </div>

                <span>{removeLiquidity.destTokenData.pool?.balanceFormatted}</span>
              </div>
              <div className="flex justify-between items-center c-gray-10 text-sm mt-4 font-light">
                <span>IOTX:</span>
                <span>{removeLiquidity.destTokenData.pool?.pooledIotxFormatted}</span>
              </div>
              <div className="flex justify-between items-center c-gray-10 text-sm mt-4 mb-4 font-light">
                <span>{removeLiquidity.destTokenData.symbol}:</span>
                <span>{removeLiquidity.destTokenData.pool?.pooledTokenFormatted}</span>
              </div>
            </div>
          )}
        </div>
        <RemoveLiquidityConfirmModal />
        <RemoveLiquiditySubmittedModal />
      </div>
    </AppLayout>
  ));
};
