import { observable, action, computed } from "mobx";
import remotedev from "mobx-remotedev";
import { publicConfig } from "../../../configs/public";
import { TokenTradeMetadata } from "../../type";
import { rootStore } from "./index";
import { Debounce } from "lodash-decorators";
import { Modal } from "antd";

// @remotedev({ name: "setting" })
export class SettingStore {
  SlippageTolerance = [10, 50, 100];

  @observable data = {
    userSlippageTolerance: 50,
    userDarkMode: false,
    userDeadline: 60,
    userExpertMode: false,
    approveAll: false,
    tokens: {} as {
      [key: string]: {
        [key: string]: Partial<TokenTradeMetadata>;
      };
    },
  };

  @action.bound
  async init() {
    let setting = localStorage.getItem("setting");
    if (setting) {
      setting = JSON.parse(setting);
      Object.assign(this.data, setting);
    }
  }

  @computed
  get settingStatus() {
    if (!this.data.userExpertMode && this.state.userSlippageTolerance >= 50) {
      return {
        error: rootStore.lang.t("transaction_invalid_percentage"),
      };
    }
    if (this.state.userSlippageTolerance < 0.1) {
      return {
        error: rootStore.lang.t("transaction_may_fail"),
      };
    }
    if (this.state.userSlippageTolerance > 5) {
      return {
        warning: rootStore.lang.t("transaction_may_frontrun"),
      };
    }
    return {
      error: null,
      warning: null,
    };
  }

  @computed
  get state() {
    const tokens = this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT];
    return {
      userSlippageTolerance: this.data.userSlippageTolerance / 100,
      userDeadline: this.data.userDeadline / 60,
      tokens,
    };
  }

  @action.bound
  setDeadLine(val) {
    this.data.userDeadline = val * 60;
    this.save();
  }

  @action.bound
  setUserSlippageTolerance(val) {
    this.data.userSlippageTolerance = Number(val);
  }

  @action.bound
  set(key: keyof SettingStore["data"], val: any) {
    //@ts-ignore
    this.data[key] = val;
    this.save();
  }

  @action.bound
  setToken(token: Partial<TokenTradeMetadata>) {
    if (!this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT]) {
      this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT] = {};
    }
    const { address, symbol, decimals, state } = token;
    this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT][address] = {
      address,
      symbol,
      decimals,
      state,
    };
    token.state.saved = true;
    this.save();
  }

  @action.bound
  removeTokens(token: Partial<TokenTradeMetadata>) {
    if (!this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT]) {
      this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT] = {};
    }
    delete this.data.tokens[publicConfig.IOTEX_CORE_ENDPOPINT][token.address];
    rootStore.token.removeTokens(token);
    token.state.saved = false;
    this.save();
  }

  getActionDeadLine() {
    return Math.floor(Date.now() / 1000 + Number(this.data.userDeadline));
  }

  @Debounce(100)
  save() {
    if (Number(this.data.userSlippageTolerance) >= 5000) {
      this.data.userSlippageTolerance = 500;
    }

    localStorage.setItem("setting", JSON.stringify(this.data));
  }
}
