import React, { useEffect } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import { notification } from "antd";
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";

import "./App.scss";
import { useStore, rootStore } from "../common/store/index";
import { LiquidityAdd } from "./pages/LiquidityAdd";
import { LiquidityRemove } from "./pages/LiquidityRemove";
import { ImportPool } from "./pages/ImportPool";
import { eventBus } from "../common/utils/eventBus";
import { publicConfig } from "../../configs/public";
import { AppIndex } from "./pages/Index/index";
import { useLocalStore } from "mobx-react";
import { utils } from "../common/utils/index";
import ConnectorModal from "./components/ConnectorModal";
import { css } from "../common/utils/stitches.config";
import { Toaster } from "react-hot-toast";

css.global({
  body: {}
});

export const App = () => {
  //@ts-ignore
  const { lang, wallet, token, setting } = useStore();
  const store = useLocalStore(() => ({
    async init() {
      await lang.init();
      await setting.init();
      await wallet.init();
      await token.init();
      rootStore.base.onInited();
    },
    setUpDevConcole() {
      if (utils.env.isMobile()) {
        //@ts-ignore
        const eruda = window.eruda;
        if (eruda) {
          eruda.init();
        }
      }
    }
  }));

  useEffect(() => {
    store.init();
    store.setUpDevConcole();
    eventBus.on("client.action.success", actionHistory => {
      notification.open({
        duration: 5,
        icon: <CheckCircleOutlined className="c-third" />,
        message: <div>{actionHistory.summary}</div>,
        description: (
          <a href={`${publicConfig.IOTEXSCAN_ENDPOINT}/action/${actionHistory.actionHash}`} target="_blank" className="c-third">
            {lang.t("view_on_iotexscan")}
          </a>
        ),
        closeIcon: <CloseOutlined className="c-gray text-xl" />
      });
    });
    return () => {
      eventBus.removeAllListeners("client.action.success");
    };
  }, []);

  return (
    <BrowserRouter>
      <ConnectorModal />
      <Toaster />
      <Switch>
        <Route exact path="/add-liquidity" component={LiquidityAdd} />
        <Route exact path="/remove-liquidity" component={LiquidityRemove} />
        <Route exact path="/pool" component={AppIndex} />
        <Route exact path="/swap" component={AppIndex} />
        <Route exact path="/wiotx" component={AppIndex} />
        <Route exact path="/import-pool" component={ImportPool} />
        <Redirect to="/swap" />
      </Switch>
    </BrowserRouter>
  );
};
