import React, { useEffect } from "react";
import "./index.scss";
import { Button, Avatar, Slider, Tooltip } from "antd";
import { ArrowLeftOutlined, QuestionCircleOutlined, PlusOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useStore } from "../../../common/store/index";
import { TokenMetadata } from "../../../type";
import { TokenSelectField } from "../../components/TokenSelectField";
import { AppLayout } from "../../layouts/AppLayout";

export const ImportPool = () => {
  const { wallet, token: tokenStore, lang } = useStore();
  const history = useHistory();

  const store = useLocalStore(() => ({
    sourceToken: null,
    destToken: null,
    setSourceToken(token) {
      this.sourceToken = token;
    },
    setDestToken(token) {
      this.destToken = token;
    },
    onBack() {
      history.push("/pool");
    },
  }));
  useEffect(() => {}, []);

  return useObserver(() => (
    <AppLayout>
      <div className="page__import_pool app-frame-content ">
        <div className="page__import_pool__content app-box-shadow app-frame bg-white p-3 rounded-lg">
          <div className="page__import_pool__content__header flex justify-between items-center my-2 c-gray-20">
            <Button type="text" shape="circle" icon={<ArrowLeftOutlined className="c-gray-10" />} onClick={store.onBack} />
            <span className="text-xl font-medium">{lang.t("import_pool")}</span>
            <Tooltip placement="leftTop" title={lang.t("import_pool_help")}>
              <Button type="text" shape="circle" icon={<QuestionCircleOutlined className="c-gray-10" />} />
            </Tooltip>
          </div>
          <div className="page__import_pool__content__main mt-4">
            <TokenSelectField token={store.sourceToken} onChangeToken={store.setSourceToken} showCommonBases />
            <div className="my-4 text-center">
              <PlusOutlined className="c-gray w-6" />
            </div>
            <TokenSelectField token={store.destToken} onChangeToken={store.setDestToken} showCommonBases />

            <div className="text-center my-4 text-sm c-gray-10">{lang.t("invalid_pair")}</div>
            <div className="text-center my-4 text-sm c-gray-10">{lang.t("select_a_token_to_find_your_liquidity")}</div>

            <Button type="primary" className="page__import_pool__content__main__suply w-full mt-4" size="large">
              {lang.t("approve")}
            </Button>
          </div>
        </div>
      </div>
    </AppLayout>
  ));
};
