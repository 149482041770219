import { eventBus } from "../../common/utils/eventBus";
import { AntennaUtils } from "../../common/utils/antanna";
import { rootStore } from "../../common/store/index";

export const hooks = {
  async waitAccount(timeout?) {
    return new Promise<void>((res, rej) => {
      if (AntennaUtils.getAntenna().iotx.accounts[0]) {
        res();
      } else {
        eventBus.once("client.wallet.onAccount", () => {
          res();
        });
        if (timeout) {
          setTimeout(() => {
            rej();
          }, timeout);
        }
      }
    });
  },
  async waitInit() {
    return new Promise<void>((res, rej) => {
      if (rootStore.base.inited) {
        res();
      } else {
        eventBus.once("client.inited", () => {
          res();
        });
      }
    });
  },

  async waitApprove() {
    return new Promise<void>((res, rej) => {
      eventBus.once("client.action.approve.success", () => {
        res();
      });
    });
  }
};
