import React from "react";
import { useObserver, useLocalStore } from "mobx-react-lite";
import "./index.scss";
import { useStore } from "../../../common/store";
import { Menu, Alert } from "antd";
import { useHistory, Route, Switch } from "react-router-dom";
import { Pool, Swap, WIotx } from "./components";
import { AppLayout } from "../../layouts/AppLayout";

export const AppIndex = () => {
  const { lang, swap } = useStore();
  const history = useHistory();

  const store = useLocalStore(() => ({
    get PANES() {
      return [
        { path: "/swap", tab: lang.t("home.swap") },
        { path: "/pool", tab: lang.t("home.pool") },
        { path: "/wiotx", tab: lang.t("home.wiotx") },
      ];
    },
  }));

  return useObserver(() => (
    <AppLayout>
      <div className="page__home app-frame-content ">
        <div className="text-center mb-6 c-gray-10 text-sm mx-auto px-4 md:px-0">
          {lang.t("v2.tips")}
          <a href="https://swap.mimo.exchange/" className="c-third underline">
            mimo V2
          </a>
        </div>
        <div className="page__home__content app-frame app-box-shadow bg-white p-4 rounded">
          <Menu
            className="page__home__content__top_menu flex items-center justify-center"
            mode="horizontal"
            defaultSelectedKeys={[String(store.PANES.findIndex((p) => p.path === history.location.pathname))]}
          >
            {store.PANES.map((p, i) => (
              <Menu.Item key={i} onClick={() => history.push(p.path)} className="text-center font-medium text-lg">
                {p.tab}
              </Menu.Item>
            ))}
          </Menu>
          <Switch>
            <Route path="/pool" exact component={Pool} />
            <Route path="/swap" exact component={Swap} />
            <Route path="/wiotx" exact component={WIotx} />
          </Switch>
        </div>
        <div className="text-center mt-12 c-gray-10 text-sm" style={{ marginTop: swap.dest.token && swap.dest.amount && !swap.tradeStatus.hideStat ? "12rem" : "3rem" }}>
          {lang.t("telegram.link")}{" "}
          <a className="c-third underline" target="_blank" href="https://t.me/mimoprotocol">
            {lang.t("mimoprotocol")}
          </a>
        </div>
      </div>
    </AppLayout>
  ));
};
