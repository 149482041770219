import { action, computed, observable } from "mobx";
import { rootStore } from "./index";
import BN from "bignumber.js";
import { XRC20 } from "iotex-antenna/lib/token/xrc20";
import { AntennaUtils } from "../utils/antanna";
import { utils } from "../utils";
import { publicConfig } from "../../../configs/public";
import { TokenTradeMetadata } from "../../type";
import { WIOTXContract } from "../contracts/wiotx";
import { eventBus } from "../utils/eventBus";
import { fromRau } from "iotex-antenna/lib/account/utils";

export class WIotxStore {
  @observable source = {
    amount: "",
    token: "iotx"
  };
  @observable dest = {
    amount: "",
    token: "wiotx"
  };
  @observable pendingAction = {
    actionHash: ""
  };

  @observable input: "source" | "dest" = "source";
  @observable status: "init" | "confirm" | "waiting" | "done" = "init";
  @observable wiotxToken: TokenTradeMetadata;
  @observable wiotxLoading: boolean = true;
  @observable wiotxContract = new WIOTXContract({ contractAddress: publicConfig.wiotxTokenMetadata.address });

  @action.bound
  rest() {
    this.source.amount = "";
    this.dest.amount = "";
  }

  @action.bound
  updateAmount(val) {
    this.dest.amount = val;
    this.source.amount = val;
    return this;
  }

  @computed
  get tradeStatus() {
    let wiotxMsg = "";
    if (!rootStore.wallet.account.address) {
      return {
        msg: rootStore.lang.t("home.connect_wallet"),
        connectWallet: true
      };
    }

    const balance = Number(rootStore.wallet.account.balance);
    if (rootStore.wallet.account.balance !== "" && !isNaN(balance) && balance < 1) {
      return {
        error: true,
        msg: rootStore.lang.t("insufficient_fee")
      };
    }

    if (this.source.amount === "") {
      return {
        error: true,
        msg: rootStore.lang.t("enter_an_amount")
      };
    }
    if (!this.source.token || !this.dest.token) {
      return {
        error: true,
        msg: rootStore.lang.t("select_a_token")
      };
    }
    if (this.source.amount === "" || this.dest.amount === "") {
      return {
        error: true,
        msg: rootStore.lang.t("enter_an_amount")
      };
    }

    if (this.source.token == "iotx") {
      wiotxMsg = rootStore.lang.t("home.wiotx.wrap");
    } else {
      wiotxMsg = rootStore.lang.t("home.wiotx.unwrap");
    }

    return {
      ready: true,
      msg: wiotxMsg
    };
  }


  @action.bound
  async handleSwap() {
    let actionHash;
    this.status = "waiting";
    const antenna = AntennaUtils.getAntenna();
    if (this.source.token == "iotx") {
      actionHash = await this.wiotxContract.swapIotxToWIotx(new BN(this.source.amount).multipliedBy(1e18).toFixed());
    } else {
      actionHash = await this.wiotxContract.exchangeWIotxToIotx(new BN(this.source.amount).multipliedBy(1e18).toFixed());
    }
    this.status = "done";
    rootStore.token.setActionData({
      actionHash,
      data: {
        actionHash,
        status: "init",
        type: "wrap",
        summary: `${this.source.token == "iotx" ? "Wrap" : "Unwrap"} ${this.source.amount} ${this.source.token.toUpperCase()} for ${this.dest.amount} ${this.dest.token.toUpperCase()}`,
        source: {
          token: this.source.token,
          amount: this.source.amount
        },
        dest: {
          token: this.dest.token,
          amount: this.dest.amount
        },
        from: antenna.iotx.accounts[0].address,
        addedTime: Date.now()
      }
    });
    this.dest.amount = "";
    this.source.amount = "";

    this.pendingAction.actionHash = actionHash;
    eventBus.emit("client.swap.onSwap", { actionHash });
  }

  @action.bound
  initWIotxToken() {
    if (this.wiotxToken) return this.wiotxToken;
    this.wiotxToken = {
      ...publicConfig.wiotxTokenMetadata,
      allowance: "",
      balance: "",
      balanceFormatted: "",
      label: "",
      priceToIotx: "1",
      decimals: 18
    };
    this.wiotxLoading = false;
    return this.wiotxToken;
  }

  @action.bound
  async loadWIotxBalance() {
    if (!rootStore.wallet.account.address) return false;
    return await this.wiotxContract.balanceOf({ address: rootStore.wallet.account.address });
  }

  @action.bound
  async loadWIotxSymbol() {
    if (!rootStore.wallet.account.address) return false;
    return await this.wiotxContract.symbol({ address: rootStore.wallet.account.address });
  }

  @action.bound
  async refreshWIotxBalance() {
    let balance = await this.loadWIotxBalance();
    this.wiotxToken.balance = balance;
    this.wiotxToken.balanceFormatted = fromRau(balance, "iotx");
  }
}
