import { ExchangeContract } from "./common/contracts/exchange";
import { XRC20 } from "iotex-antenna/lib/token/xrc20";
export type Dict = {
  [key: string]: string;
};

export interface TokenMetadata {
  label: string;
  address: string;
  logoURI: string;
  exchangeAddress?: string;
}

export interface TokenTradeMetadata extends TokenMetadata {
  balance: string;
  balanceFormatted: string;
  allowance: string;
  symbol: string;
  decimals?: number;
  priceToIotx: string;
  exchange?: ExchangeContract;
  xrc20?: XRC20;
  state?: {
    loading?: boolean;
    external?: boolean;
    saved?: boolean;
    warning?: boolean;
  };
  pool?: Partial<{
    totalSupply: string;
    totalSupplyFormatted: string;
    balance: string;
    balanceFormatted: string;
    share: string;
    pooledToken: string;
    pooledTokenFormatted: string;
    pooledIotx: string;
    pooledIotxFormatted: string;
    exchangeIotxBalance: string;
    exchangeIotxBalanceFormatted: string;
    exchangeXRC20Balance: string;
    exchangeXRC20BalanceFormatted: string;
    totalLiquidityFormatted: string;
    noLiquidity: boolean;
    noExchange: boolean;
  }>;
}

export interface ActionHistory {
  actionHash: string;
  summary: string;
  source?: { token: string; amount?: string };
  dest?: { token: string; amount?: string };
  from: string;
  status: "init" | "failed" | "success";
  type: "swap" | "addLiquidity" | "removeLiquidity" | "approve" | "createExchange" | "wrap";
  approval?: {
    tokenAddress: string;
    spender: string;
  };
  receipt?: any;
  addedTime: number;
  confirmedTime?: number;
}

export enum Connector {
  IopayDesktop = "iopay-desktop",
  IopayExtension = "iopay-extension",
}
