import React, { useEffect, useState } from "react";
import { Avatar, Button, Modal, Tooltip, Image } from "antd";
import { useStore } from "../../../../../common/store";
import { useObserver } from "mobx-react-lite";
import BN from "bignumber.js";
import { fromRau, validateAddress } from "iotex-antenna/lib/account/utils";
import { utils } from "../../../../../common/utils/index";
import { TradingPairIcon } from "../../../../components/TradingPairIcon/index";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { analyticsClient } from "../../../../../common/utils/gql";
import { Exchange } from "../../../../../../generated/gql/schema";
import { TokenAvatar } from "../../../../components/TokenAvatar/index";

interface IComponentProps {}

export const PoolInformationModal = (props: IComponentProps) => {
  const { lang, token } = useStore();
  const [exchange, setExchange] = useState(undefined as Exchange | undefined);
  const exchangeAddress = token.poolInfoToken?.exchangeAddress;
  useEffect(() => {
    if (validateAddress(exchangeAddress)) {
      analyticsClient.chain.query
        .exchange({
          exchange: exchangeAddress,
        })
        .execute({
          balanceOfIOTX: 0,
          volumeInPast24Hours: 0,
        })
        .then((exchange) => {
          setExchange(exchange);
        });
    }
  }, [exchangeAddress]);

  return useObserver(() => (
    <Modal
      visible={token.poolInfoModalOpen}
      className="modal__poll_info_liquidity"
      onCancel={(e) => token.togglePoolInfoModal()}
      width={415}
      title={
        <span className="flex items-center text-xl font-light">
          <TradingPairIcon tokenIcon={token.poolInfoToken?.logoURI} />
          <b className="ml-2">
            {`${token.poolInfoToken?.symbol}/IOTX`} {lang.t("pool")}
          </b>
        </span>
      }
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <div className="bg-white-30 p-6">
        <div className="flex justify-between items-center ">
          <span>{lang.t("total_liquidity")}:</span>
          <span className="flex items-center">
            {utils.helper.number.toPrecisionFloor(fromRau(token.poolInfoToken?.pool?.exchangeIotxBalance, "Iotx")) * 2}&nbsp;
            <Avatar src={"/image/iotx.png"} size="small" />
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span>
            {lang.t("pooled")} {token.poolInfoToken?.symbol}:
          </span>
          <span className="flex items-center">
            {utils.helper.number.toPrecisionFloor(new BN(token.poolInfoToken?.pool?.exchangeXRC20Balance).div(10 ** token.poolInfoToken?.decimals).toString())}&nbsp;
            <TokenAvatar src={token.poolInfoToken?.logoURI} size="small" />
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span>{lang.t("pooled")} IOTX:</span>
          <span className="flex items-center">
            {utils.helper.number.toPrecisionFloor(fromRau(token.poolInfoToken?.pool?.exchangeIotxBalance, "Iotx"))}&nbsp;
            <Avatar src={"/image/iotx.png"} size="small" />
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="mb-3 flex items-center">
            {lang.t("APROfPool")}
            &nbsp;
            <Tooltip placement="left" title={lang.t("APROfPool_title")}>
              <QuestionCircleOutlined />
            </Tooltip>
            :
          </span>
          <span className="flex items-center">{`${utils.helper.exchange.calculateAPR(exchange)}%`}</span>
        </div>
        <Button type="primary" className="text-center w-full mt-6" onClick={() => token.togglePoolInfoModal(token.poolInfoToken)}>
          {lang.t("ok")}
        </Button>
      </div>
    </Modal>
  ));
};
