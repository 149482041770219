import React, { MouseEventHandler } from "react";
import { Modal, Button, Input, Divider, Avatar } from "antd";
import { useStore } from "../../../../../common/store";
import { useObserver } from "mobx-react-lite";
import { ArrowDownOutlined, InfoCircleOutlined, QuestionCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { TokenAvatar } from "../../../../components/TokenAvatar/index";

interface IComponentProps {}

export const RemoveLiquidityConfirmModal = (props: IComponentProps) => {
  const { lang, removeLiquidity, setting } = useStore();

  return useObserver(() => (
    <Modal
      visible={["confirm", "waiting"].includes(removeLiquidity.status)}
      className="modal__remove_liquidity_confirm"
      onCancel={(e) => (removeLiquidity.status = "init")}
      width={415}
      title={<span className="c-gray-10">{lang.t("you_will_receive")}</span>}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      {removeLiquidity.status == "waiting" && (
        <div className="px-4 pb-4 text-center">
          <LoadingOutlined className="c-third text-5xl font-light" />
          <div className="c-black text-2xl mt-5 mb-4">{lang.t("swap.waiting_for_confimration")}</div>
          <div className="c-gray text-lg mb-10">
            {lang.t("remove_liquidity.supply_comment", {
              sourceAmount: removeLiquidity.source.amount,
              sourceToken: removeLiquidity.sourceTokenData?.symbol,
              destAmount: removeLiquidity.dest.amount,
              destToken: removeLiquidity.destTokenData?.symbol,
            })}
          </div>
          <div className="c-gray-10 text-sm mb-5">{lang.t("swap.confirm_transaction")}</div>
        </div>
      )}
      {removeLiquidity.status == "confirm" && (
        <>
          <div className="px-4 modal__swap_confirm__top">
            <div>
              <div className="flex items-center justify-between">
                <div></div>
                <div className="flex items-center">
                  <span className="text-2xl c-gray-30  mr-2">{removeLiquidity.sourceTokenData.amount}</span>
                  <span className="text-xl c-gray-30  mr-2">{removeLiquidity.sourceTokenData?.symbol}</span>
                  <TokenAvatar src={removeLiquidity.sourceTokenData?.logoURI} className="app-avatar" />{" "}
                </div>
              </div>
              <div className="mb-2"></div>
              <div className="flex items-center justify-between">
                <div></div>
                <div className="flex items-center">
                  <span className="text-2xl c-gray-30 mr-2">{removeLiquidity.destTokenData.amount}</span>
                  <span className="text-xl c-gray-30  mr-2">{removeLiquidity.destTokenData?.symbol}</span>
                  <TokenAvatar src={removeLiquidity.destTokenData?.logoURI} className="app-avatar" />
                </div>
              </div>
            </div>
            <div className="font-light text-sm mt-4 mb-4 c-gray-10">{lang.t("liquid.remove.helper", { percent: (setting.data.userSlippageTolerance / 100).toString() })}</div>
          </div>
          <div className="bg-white-30 p-4 modal__swap_confirm__bottom">
            <div className="text-base c-gray font-light">
              <div className="flex justify-between items-center mb-3"></div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">{lang.t("uni_burned_amount")}</div>
                <span>
                  <span>{removeLiquidity.destTokenData?.tokenBurned} </span>
                  <span>LP:</span>
                  <span>
                    {removeLiquidity.sourceTokenData?.symbol}/{removeLiquidity.destTokenData?.symbol}
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">{lang.t("price")}&nbsp;</div>
                <div className="text-right">
                  <div>
                    1 {removeLiquidity.sourceTokenData?.symbol} = {removeLiquidity.sourceDestRate} {removeLiquidity.destTokenData?.symbol}
                  </div>
                  <div>
                    1 {removeLiquidity.destTokenData?.symbol} = {removeLiquidity.destSourceRate} {removeLiquidity.sourceTokenData?.symbol}
                  </div>
                </div>
              </div>
            </div>
            <Button type="primary" className="modal__swap_confirm__bottom__confirm text-center w-full" size="large" onClick={removeLiquidity.handleRemoveLiquidity}>
              {lang.t("confirm")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  ));
};
