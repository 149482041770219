import React, { useEffect } from "react";
import { Modal, Button, Tooltip, Divider, Avatar, Image } from "antd";
import { useStore } from "../../../../../common/store";
import { useObserver } from "mobx-react-lite";
import { ArrowDownOutlined, InfoCircleOutlined, QuestionCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocalStore } from "mobx-react";
import { hooks } from "../../../../utils/hooks";
import { utils } from "../../../../../common/utils";
import { TokenAvatar } from "../../../../components/TokenAvatar/index";

interface IComponentProps {}

export const SwapConfirmModal = (props: IComponentProps) => {
  const { lang, swap, token } = useStore();

  const store = useLocalStore(() => ({
    cache: {
      destAmount: "",
    },
    get priceUpdate() {
      return swap.dest.amount !== store.cache.destAmount;
    },
    acceptPrice() {
      store.cache.destAmount = swap.dest.amount;
    },
    loadLatestPrice() {
      if (store.priceUpdate || swap.status !== "confirm") return;
      setTimeout(async () => {
        await Promise.all([token.loadToken(swap.sourceTokenData), token.loadToken(swap.destTokenData)]);
        await swap.updateAmount("dest");
        store.loadLatestPrice();
      }, 10000);
    },
  }));

  useEffect(() => {
    if (swap.status == "confirm") {
      hooks.waitAccount().then(() => {
        store.cache.destAmount = swap.dest.amount;
        store.loadLatestPrice();
      });
    }
  }, [swap.status]);

  return useObserver(() => (
    <Modal
      visible={["confirm", "waiting"].includes(swap.status)}
      className="modal__swap_confirm"
      onCancel={(e) => (swap.status = "init")}
      width={420}
      title={<span className={`c-gray ${swap.status == "waiting" ? "opacity-0" : ""}`}>{lang.t("swap.you_will_receive")}</span>}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      {swap.status == "waiting" && (
        <div className="px-4 pb-4 text-center">
          <LoadingOutlined className="c-third text-5xl font-light" />
          <div className="c-black text-2xl mt-5 mb-4">{lang.t("swap.waiting_for_confimration")}</div>
          <div className="c-gray text-lg mb-10">
            {lang.t("swap.swapping_comment", {
              sourceAmount: swap.source.amount,
              sourceToken: swap.sourceTokenData?.symbol,
              destAmount: swap.dest.amount,
              destToken: swap.destTokenData?.symbol,
            })}
          </div>
          <div className="c-gray-10 text-sm mb-5">{lang.t("swap.confirm_transaction")}</div>
        </div>
      )}
      {swap.status == "confirm" && (
        <>
          <div className="px-4 modal__swap_confirm__top">
            <div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <TokenAvatar src={swap.sourceTokenData?.logoURI} className="app-avatar" />
                  <span className="text-2xl c-gray-30 ml-2">{swap.source.amount}</span>
                </div>
                <div>
                  <span className="text-xl c-gray-30">{swap.sourceTokenData?.symbol}</span>
                </div>
              </div>
              <div className="mt-1 mb-1">
                <img className="w-6" src="/image/icon_arrow_down.png" alt="" />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <TokenAvatar src={swap.destTokenData?.logoURI} className="app-avatar" />
                  <span className="text-2xl c-gray-30 ml-2">{swap.dest.amount}</span>
                </div>
                <div>
                  <span className="text-xl c-gray-30">{swap.destTokenData?.symbol}</span>
                </div>
              </div>
            </div>
            <div className="font-light text-sm mt-4 mb-4 c-gray-10">{lang.t("swap.confirm_comment", { amount: swap.stats.minimunRecceived.formatted, token: swap.destTokenData?.symbol })}</div>
            {store.priceUpdate && (
              <div className="flex justify-between items-center c-third rounded bg-white-30 py-2 pr-2 pl-3 mb-4 font-light">
                <span className="flex items-center">
                  <InfoCircleOutlined />
                  &nbsp; {lang.t("swap.price update")}
                </span>
                <Button type="primary" onClick={store.acceptPrice}>
                  {lang.t("accept")}
                </Button>
              </div>
            )}
          </div>
          <div className="bg-white-30 p-4 modal__swap_confirm__bottom">
            <div className="text-base c-gray font-light">
              <div className="flex justify-between items-center mb-3">
                <span>{lang.t("price")}</span>
                <span className="flex items-center ">
                  {swap.sourceToDestRate
                    ? `${utils.helper.number.toPrecisionFloor(swap.stats.destSourceRate.toFixed())} ${swap.sourceTokenData?.symbol} per ${swap.destTokenData?.symbol}`
                    : `${utils.helper.number.toPrecisionFloor(swap.stats.sourceDestRate.toFixed())} ${swap.destTokenData?.symbol} per ${swap.sourceTokenData?.symbol}`}
                  <img src="/image/icon_swap.png" className="h-6 w-6" onClick={(e) => (swap.sourceToDestRate = !swap.sourceToDestRate)} />
                </span>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  {swap.input == "source" ? lang.t("minimum_received") : lang.t("maximun_sold")}&nbsp;
                  <Tooltip placement="right" title={lang.t("minimum_received_tips")} trigger="hover">
                    <QuestionCircleOutlined className="c-gray-10" />
                  </Tooltip>
                </div>
                <span>
                  <span>{swap.stats.minimunRecceived.formatted}</span>
                  <span className="ml-1">{swap.input == "source" ? swap.destTokenData.symbol : swap.sourceTokenData.symbol}</span>
                </span>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  {lang.t("price_impact")}&nbsp;
                  <Tooltip placement="right" title={lang.t("price_impact_tips")} trigger="hover">
                    <QuestionCircleOutlined className="c-gray-10" />
                  </Tooltip>
                </div>
                <span className={`${Number(swap.stats.priceImpace) <= 3 ? "c-third" : "text-red-600"}`}>{swap.stats.priceImpace}%</span>
              </div>
              <div className="flex items-center justify-between mb-16">
                <div className="flex items-center">
                  {lang.t("liquidity_provider_fee")}&nbsp;
                  <Tooltip placement="right" title={lang.t("liquidity_provider_fee_tips")} trigger="hover">
                    <QuestionCircleOutlined className="c-gray-10" />
                  </Tooltip>
                </div>
                <span>
                  {swap.stats.liquidityProviderFee.toFixed(6)} {swap.sourceTokenData.symbol}
                </span>
              </div>
            </div>
            <Button disabled={store.priceUpdate} type="primary" className="modal__swap_confirm__bottom__confirm text-center w-full" size="large" onClick={swap.handleSwap}>
              {lang.t("confirm_swap")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  ));
};
