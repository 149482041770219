import { tokenMetadata, wiotxTokenMetadata } from "./metadata";
const { NODE_ENV, FACTORY_CONTRACT_ADDRESS, SENTRY_DSN, FORCE_HTTPS, PORT, IOTEX_CORE_ENDPOPINT, COIN_LIST_LIMIT = 100000 } = process.env;

const IS_PROD = NODE_ENV == "production";

export const publicConfig = {
  IS_PROD,
  NODE_ENV,
  PORT: PORT || 3000,
  IOTEXSCAN_ENDPOINT: IS_PROD ? "https://iotexscan.io" : "https://testnet.iotexscan.io",
  IOTEX_CORE_ENDPOPINT: IOTEX_CORE_ENDPOPINT || (IS_PROD ? "https://api.iotex.one:443" : "https://api.testnet.iotex.one:443"),
  FACTORY_CONTRACT_ADDRESS: FACTORY_CONTRACT_ADDRESS || (IS_PROD ? "io1w0sytg3w8fl6zewp4r38w4w9dlq6765uyk9s8j" : "io1vu0tq2v6ph5xhwrrpx0vzvg5wt8adfk3ygnxfj"),
  tokenMetadata: IS_PROD ? tokenMetadata.mainnet : tokenMetadata.testnet,
  wiotxTokenMetadata: IS_PROD ? wiotxTokenMetadata.mainnet : wiotxTokenMetadata.testnet,
  SENTRY_DSN: SENTRY_DSN,
  ANALYTICS_GRAPHQL_APIENDPOINT: IS_PROD ? "https://mimo-analytics.herokuapp.com/query" : "http://mimo-analytics-testnet.herokuapp.com/query",
  COIN_LIST_LIMIT
};
