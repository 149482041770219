import { observable, action, computed } from "mobx";
import remotedev from "mobx-remotedev";
import { rootStore } from "./index";
import BN from "bignumber.js";
import { AntennaUtils } from "../utils/antanna";
import { eventBus } from "../utils/eventBus";
import { utils } from "../utils/index";
import { toRau, fromRau } from "iotex-antenna/lib/account/utils";

// @remotedev({ name: "removeLiquidity" })
export class RemoveLiquidityStore {
  @observable percent = 0;

  @observable pendingAction = {
    actionHash: "",
  };

  @observable status: "init" | "confirm" | "waiting" | "done" = "init";

  @observable source = {
    amount: "",
    token: "",
  };
  @observable dest = {
    amount: "",
    token: "",
  };

  @computed
  get destSourceRate() {
    if (Number.isNaN(this.destTokenData.priceToIotx)) return "-";
    return utils.helper.number.toPrecisionFloor(this.destTokenData.priceToIotx);
  }

  @computed
  get sourceDestRate() {
    if (Number.isNaN(this.destTokenData.priceToIotx) || Number(this.destTokenData.priceToIotx) === 0) return "-";
    return utils.helper.number.toPrecisionFloor(1 / Number(this.destTokenData.priceToIotx));
  }

  @computed
  get sourceTokenData() {
    const token = this.source.token;
    const tokenMeta = rootStore.token.tokens[token];
    const target = this.source;
    const amount = utils.helper.number.toPrecisionFloor(target.amount);
    if (token == "iotx") {
      return {
        ...tokenMeta,
        ...this.source,
        balance: rootStore.wallet.account.balance,
        allowance: rootStore.wallet.account.balance,
        amount,
        target,
      };
    }
    return { ...tokenMeta, target, amount };
  }

  @computed
  get destTokenData() {
    const token = this.dest.token;
    const tokenMeta = rootStore.token.tokens[token];
    const target = this.dest;
    const amount = utils.helper.number.toPrecisionFloor(target.amount);
    const tokenBurnedRaw = tokenMeta?.pool?.balance ? new BN(tokenMeta?.pool?.balance).div(100).multipliedBy(this.percent).toFixed(0) : "";
    const tokenBurned = tokenBurnedRaw ? utils.helper.number.toPrecisionFloor(new BN(tokenBurnedRaw).div(1e18).toFixed()) : "";
    if (token == "iotx") {
      return {
        ...tokenMeta,
        ...this.dest,
        balance: rootStore.wallet.account.balance,
        allowance: rootStore.wallet.account.balance,
        target,
        amount,
        tokenBurnedRaw,
        tokenBurned,
      };
    }
    return { ...tokenMeta, target, amount, tokenBurnedRaw, tokenBurned };
  }

  @computed
  get tradeStatus() {
    if (!rootStore.wallet.account.address) {
      return {
        msg: rootStore.lang.t("home.connect_wallet"),
      };
    }
    if (!this.source.token || !this.dest.token) {
      return {
        error: true,
        msg: rootStore.lang.t("invalid_pair"),
      };
    }
    if (this.source.amount === "" || this.dest.amount === "") {
      return {
        error: true,
        msg: rootStore.lang.t("enter_an_amount"),
      };
    }
    if (Number(this.sourceTokenData?.balance) < Number(this.source.amount)) {
      return {
        error: true,
        msg: rootStore.lang.t("insufficient_balance", { symbol: this.sourceTokenData?.symbol }),
      };
    }
    if (Number(this.destTokenData?.balance) < Number(this.dest.amount)) {
      return {
        error: true,
        msg: rootStore.lang.t("insufficient_balance", { symbol: this.destTokenData?.symbol }),
      };
    }
    let allownces = [];
    if (Number(this.sourceTokenData?.allowance) < Number(this.source.amount) && Number(this.source.amount) != 0) {
      allownces.push({ token: this.source.token, amount: this.source.amount });
    }
    if (Number(this.destTokenData?.allowance) < Number(this.dest.amount) && Number(this.dest.amount) != 0) {
      allownces.push({ token: this.dest.token, amount: this.dest.amount });
    }
    if (allownces.length) {
      return {
        error: true,
        allownces,
        msg: rootStore.lang.t("supply"),
      };
    }

    return {
      msg: rootStore.lang.t("supply"),
    };
  }

  @action.bound
  reset() {
    this.percent = 0;
    this.source.amount = "";
    this.dest.amount = "";
  }

  @action.bound
  setPercent(value) {
    this.percent = value;
    if (!this.destTokenData.pool) return;
    this.source.amount = new BN(this.destTokenData.pool?.pooledIotxFormatted).div(100).multipliedBy(this.percent).toFixed();
    this.dest.amount = new BN(this.destTokenData.pool?.pooledTokenFormatted).div(100).multipliedBy(this.percent).toFixed();
  }

  @action.bound
  async handleAmount({ key, val }: { key: string; val }) {
    const input = key == "source" ? this.sourceTokenData : this.destTokenData;
    const output = key == "source" ? this.destTokenData : this.sourceTokenData;
    input.target.amount = val;

    if (input.target.amount) {
      if (input.symbol == "IOTX") {
        const price = new BN(input.target.amount).dividedBy(new BN(output.priceToIotx)).toFixed();
        output.target.amount = price;
      } else {
        if (output.symbol == "IOTX") {
          const price = new BN(input.target.amount).multipliedBy(new BN(input.priceToIotx)).toFixed();
          output.target.amount = price;
        } else {
          const price = new BN(input.target.amount).multipliedBy(new BN(input.priceToIotx).dividedBy(new BN(output.priceToIotx))).toFixed();
          output.target.amount = price;
        }
      }
    }
  }

  @action.bound
  async handleRemoveLiquidity() {
    this.status = "waiting";
    const antenna = AntennaUtils.getAntenna();
    const exchange = this.destTokenData.exchange;
    const amount = this.destTokenData.tokenBurnedRaw;
    const min_iotx = toRau(this.sourceTokenData.amount, "iotx");
    const min_tokens_bought = new BN(this.destTokenData.amount).multipliedBy(10 ** this.destTokenData.decimals).toFixed(0, 1);

    console.log({ amount, min_iotx, min_tokens_bought, deadline: rootStore.setting.getActionDeadLine() });

    const actionHash = await exchange.removeLiquidity({ amount, min_iotx, min_tokens_bought, deadline: rootStore.setting.getActionDeadLine() });

    rootStore.token.setActionData({
      actionHash,
      data: {
        actionHash,
        type: "removeLiquidity",
        source: {
          token: this.source.token,
        },
        dest: {
          token: this.dest.token,
        },
        status: "init",
        summary: `Remove ${this.sourceTokenData.amount} ${this.sourceTokenData.symbol} and ${this.destTokenData.amount} ${this.destTokenData.symbol}`,
        from: antenna.iotx.accounts[0].address,
        addedTime: Date.now(),
      },
    });

    this.pendingAction.actionHash = actionHash;
    this.status = "done";
    eventBus.emit("client.addLiquidity.onRemove", { actionHash });
  }
}
