import { FactoryABI } from "../constants/abi";
import { AntennaUtils } from "../utils/antanna";
import { IContract } from "./iContract";
import Antenna from "iotex-antenna";

export class FactoryContract {
  contract: IContract;
  antenna: Antenna;
  constructor({ contractAddress }: { contractAddress: string }) {
    this.antenna = AntennaUtils.getAntenna();
    this.contract = new IContract(FactoryABI, contractAddress, { provider: this.antenna.iotx, signer: this.antenna.iotx.signer });
  }

  async getExchange(address: string) {
    return this.contract.methods.getExchange(address, {
      account: address,
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async createExchange(address: string) {
    return this.contract.methods.createExchange(address, {
      account: AntennaUtils.getAntenna().iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
      gasLimit: 5000000
    });
  }
}
