import { observable, action, computed } from "mobx";
import remotedev from "mobx-remotedev";
import { rootStore } from "./index";
import BN from "bignumber.js";
import { AntennaUtils } from "../utils/antanna";
import { eventBus } from "../utils/eventBus";
import { utils } from "../utils/index";
import { contracts } from "../contracts/index";
import { toRau } from "iotex-antenna/lib/account/utils";

@remotedev({ name: "addliquidity" })
export class AddLiquidityStore {
  @observable pendingAction = {
    actionHash: ""
  };

  @observable status: "init" | "confirm" | "waiting" | "done" = "init";

  @observable
  source = {
    amount: "",
    token: "init"
  };
  @observable
  dest = {
    amount: "",
    token: ""
  };

  @observable input = "source";

  @computed
  get stats() {
    if (!Number(this.destTokenData.priceToIotx)) {
      return {
        destSourceRate: "-",
        sourceDestRate: "-",
        shareOfPool: "<0.01",
        liquidity_minted: ""
      };
    }
    // filter invalid priceIotx
    if (this.destTokenData.pool?.noLiquidity || this.destTokenData.priceToIotx.length > 50) {
      return {
        destSourceRate: new BN(this.source.amount || 0).dividedBy(new BN(this.dest.amount || 0)).toFixed(),
        sourceDestRate: new BN(this.dest.amount || 0).dividedBy(new BN(this.source.amount || 0)).toFixed(),
        shareOfPool: "100",
        liquidity_minted: this.source.amount
      };
    }
    return {
      destSourceRate: this.destTokenData.priceToIotx ? utils.helper.number.toPrecisionFloor(this.destTokenData.priceToIotx) : "",
      sourceDestRate: this.destTokenData.priceToIotx ? utils.helper.number.toPrecisionFloor(1 / Number(this.destTokenData.priceToIotx)) : "",
      shareOfPool: this.getShareOfPool(),
      liquidity_minted: this.getLiquidityMinted()
    };
  }

  @computed
  get sourceTokenData() {
    const token = this.source.token;
    const tokenMeta = rootStore.token.tokens[token];
    const target = this.source;
    const amountRaw = new BN(target.amount).multipliedBy(tokenMeta?.decimals ? 10 ** tokenMeta.decimals : 1e18).toFixed();
    const amountFormatted = utils.helper.number.toPrecisionFloor(target.amount);

    if (token == "iotx") {
      return {
        ...tokenMeta,
        ...this.source,
        amountRaw,
        amountFormatted,
        balance: rootStore.wallet.account.balanceRaw,
        allowance: rootStore.wallet.account.balanceRaw,
        symbol: "IOTX",
        decimals: 18,
        target
      };
    }
    return { ...tokenMeta, target, amountFormatted, amountRaw };
  }

  @computed
  get destTokenData() {
    const token = this.dest.token;
    const tokenMeta = rootStore.token.tokens[token];
    const target = this.dest;
    const amountRaw = new BN(target.amount).multipliedBy(tokenMeta?.decimals ? 10 ** tokenMeta.decimals : 1e18).toFixed();
    const amountFormatted = utils.helper.number.toPrecisionFloor(target.amount);
    if (token == "iotx") {
      return {
        ...tokenMeta,
        amountRaw,
        amountFormatted,
        balance: rootStore.wallet.account.balanceRaw,
        allowance: rootStore.wallet.account.balanceRaw,
        ...this.dest,
        symbol: "IOTX",
        decimals: 18,
        target
      };
    }
    return { ...tokenMeta, amountFormatted, target, amountRaw };
  }

  @computed
  get tradeStatus() {
    const status = {
      msg: rootStore.lang.t("supply"),
      error: false,
      allownces: [],
      connectWallet: false,
      noLiquidity: this.destTokenData.pool?.noLiquidity,
      noExchange: this.destTokenData.pool?.noExchange
    };
    if (!rootStore.wallet.account.address) {
      return {
        ...status,
        connectWallet: true,
        msg: rootStore.lang.t("home.connect_wallet")
      };
    }
    if (!this.source.token || !this.dest.token) {
      return {
        ...status,
        error: true,
        msg: rootStore.lang.t("invalid_pair")
      };
    }
    if (this.destTokenData.pool?.noExchange) {
      return {
        error: true,
        ...status,
        msg: rootStore.lang.t("create_pool")
      };
    }
    if (this.source.amount === "" || this.dest.amount === "") {
      return {
        ...status,
        error: true,
        msg: rootStore.lang.t("enter_an_amount")
      };
    }
    // let decimals = this.sourceTokenData.address != "iotx" && this.sourceTokenData?.decimals ? this.sourceTokenData?.decimals : 18;
    // if (Math.exp(-1 * decimals) > Number(this.source.amount)) {
    //   return {
    //     ...status,
    //     error: true,
    //     msg: `${this.sourceTokenData.symbol} amount >= ${Math.exp(-1 * decimals).toFixed(12)}`,
    //   };
    // }

    if (Number(this.sourceTokenData?.balance) < Number(this.sourceTokenData.amountRaw)) {
      return {
        ...status,
        error: true,
        msg: `Insufficient ${this.sourceTokenData?.symbol} balance`
      };
    }

    // decimals = this.destTokenData.address != "iotx" && this.destTokenData?.decimals ? this.destTokenData?.decimals : 18;
    // if (Math.exp(-1 * decimals) > Number(this.dest.amount)) {
    //   return {
    //     ...status,
    //     error: true,
    //     msg: `${this.destTokenData.symbol} amount >= ${Math.exp(-1 * decimals).toFixed(12)}`,
    //   };
    // }

    if (Number(this.destTokenData?.balance) < Number(this.destTokenData.amountRaw)) {
      return {
        ...status,
        error: true,
        msg: rootStore.lang.t("insufficient_balance", { symbol: this.destTokenData?.symbol })
      };
    }
    if (Number(this.sourceTokenData?.allowance) < Number(this.source.amount) && Number(this.source.amount) != 0) {
      status.allownces.push({ token: this.source.token, amount: Number(this.source.amount) / 0.99 });
    }
    if (Number(this.destTokenData?.allowance) < Number(this.dest.amount) && Number(this.dest.amount) != 0) {
      status.allownces.push({ token: this.dest.token, amount: Number(this.dest.amount) / 0.99 });
    }

    if (status.allownces.length) {
      return {
        ...status,
        error: true,
        msg: rootStore.lang.t("supply")
      };
    }
    if (this.destTokenData.pool?.noLiquidity) {
      return {
        ...status,
        msg: rootStore.lang.t("add_first_liquidity")
      };
    }

    return {
      ...status,
      error: false,
      msg: rootStore.lang.t("supply")
    };
  }

  reset() {
    this.input = "source";
    this.source.amount = "";
    this.dest.amount = "";
  }

  @action.bound
  async handleAmount({ key, val }: { key: string; val: any }) {
    const input = key == "source" ? this.sourceTokenData : this.destTokenData;
    const output = key == "source" ? this.destTokenData : this.sourceTokenData;
    this.input = key;
    input.target.amount = val;
    if (this.tradeStatus.noLiquidity) return;

    if (input.target.amount) {
      if (input.symbol == "IOTX") {
        const price = new BN(input.target.amount).dividedBy(new BN(output.priceToIotx)).toFixed();
        output.target.amount = price;
      } else {
        if (output.symbol == "IOTX") {
          const price = new BN(input.target.amount).multipliedBy(new BN(input.priceToIotx)).toFixed();
          output.target.amount = price;
        } else {
          const price = new BN(input.target.amount).multipliedBy(new BN(input.priceToIotx).dividedBy(new BN(output.priceToIotx))).toFixed();
          output.target.amount = price;
        }
      }
    }
  }

  @action.bound
  async handleSupply() {
    this.status = "waiting";

    const antenna = AntennaUtils.getAntenna();
    const exchange = this.destTokenData.exchange;
    const amount = toRau(this.sourceTokenData.amountFormatted, "iotx");
    const min_liquidity = new BN(this.stats.liquidity_minted)
      .multipliedBy(1e18)
      .multipliedBy(0.99)
      .toFixed();
    let max_tokens = this.tradeStatus.noLiquidity
      ? new BN(Number(this.destTokenData.amountFormatted)).multipliedBy(10 ** this.destTokenData.decimals).toFixed(0)
      : new BN(Number(this.destTokenData.amountFormatted))
          .div(0.99)
          .multipliedBy(10 ** this.destTokenData.decimals)
          .toFixed(0);
    const actionHash = await exchange.addLiquidity({
      min_liquidity,
      max_tokens,
      deadline: rootStore.setting.getActionDeadLine(),
      amount
    });
    this.status = "done";

    rootStore.token.setActionData({
      actionHash,
      data: {
        actionHash,
        status: "init",
        type: "addLiquidity",
        source: {
          token: this.source.token,
          amount: this.source.amount
        },
        dest: {
          token: this.dest.token,
          amount: this.dest.amount
        },
        summary: `Add ${this.source.amount} ${this.sourceTokenData.symbol} and ${this.dest.amount} ${this.destTokenData.symbol}`,
        from: antenna.iotx.accounts[0].address,
        addedTime: Date.now()
      }
    });

    this.pendingAction.actionHash = actionHash;
    eventBus.emit("client.addLiquidity.onSupply", { actionHash });
  }

  @action.bound
  async handleCreateExchange() {
    const antenna = AntennaUtils.getAntenna();
    const address = this.destTokenData.address;
    const actionHash = await contracts.factoryContract.createExchange(address);

    rootStore.token.setActionData({
      actionHash,
      data: {
        actionHash,
        status: "init",
        type: "createExchange",
        source: {
          token: this.source.token
        },
        dest: {
          token: this.dest.token
        },
        summary: `Create Exchange for ${this.destTokenData.symbol}`,
        from: antenna.iotx.accounts[0].address,
        addedTime: Date.now()
      }
    });
  }

  getShareOfPool() {
    if (!this.dest.amount || !this.source.amount) return "<0.01";
    return new BN(this.dest.amount)
      .div(new BN(this.destTokenData.pool?.exchangeXRC20BalanceFormatted).plus(this.dest.amount))
      .multipliedBy(100)
      .toFixed(2);
  }

  getLiquidityMinted() {
    return utils.helper.number.toPrecisionFloor(
      new BN(this.source.amount)
        .multipliedBy(this.destTokenData.pool?.totalSupplyFormatted)
        .div(this.destTokenData.pool?.exchangeIotxBalanceFormatted || 0)
        .toString()
    );
  }
}
