import React from "react";
import { BaseStore } from "./base";
import { LangStore } from "./lang";
import { WalletStore } from "./wallet";
import { SettingStore } from "./setting";
import { TokenStore } from "./token";
import { AddLiquidityStore } from "./addLiquidity";
import { SwapStore } from "./swap";
import { RemoveLiquidityStore } from "./removeLiquidity";
import { WIotxStore } from "./wiotx";
import { toJS } from "mobx";

export const rootStore = {
  base: new BaseStore(),
  lang: new LangStore(),
  wallet: new WalletStore(),
  setting: new SettingStore(),
  token: new TokenStore(),
  addLiquidity: new AddLiquidityStore(),
  removeLiquidity: new RemoveLiquidityStore(),
  swap: new SwapStore(),
  wiotx: new WIotxStore()
};

export const StoresContext = React.createContext(rootStore);

export const useStore = () => React.useContext(StoresContext);

//@ts-ignore
window.store = new Proxy(rootStore, {
  get(target, props, receiver) {
    return toJS(target[props]);
  }
});
