import { WIotxABI } from "../constants/abi";
import { AntennaUtils } from "../utils/antanna";
import { IContract } from "./iContract";
import Antenna from "iotex-antenna";

export class WIOTXContract {
  contract: IContract;
  antenna: Antenna;
  constructor({ contractAddress }: { contractAddress: string }) {
    this.antenna = AntennaUtils.getAntenna();
    this.contract = new IContract(WIotxABI, contractAddress, { provider: this.antenna.iotx, signer: this.antenna.iotx.signer });
  }

  async exchangeWIotxToIotx(val) {
    return this.contract.methods.withdraw(val, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async swapIotxToWIotx(val) {
    return this.contract.methods.deposit({
      amount: val,
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async balanceOf({ address }: { address: string }) {
    const res = await this.contract.readContract({ method: "balanceOf", args: [address], from: this.contract.getAddress() });
    return res.toString();
  }
  async symbol({ address }: { address: string }) {
    const res = await this.contract.readContract({ method: "symbol", args: [], from: this.contract.getAddress() });
    return res.toString();
  }
}
