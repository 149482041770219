import { observable, action, computed } from "mobx";
import remotedev from "mobx-remotedev";
import { eventBus } from "../utils/eventBus";

// @remotedev({ name: "base" })
export class BaseStore {
  @observable
  inited = false;
  @observable NODE_ENV = "";

  @action.bound
  onInited() {
    this.inited = true;
    eventBus.emit("client.inited");
  }
}
