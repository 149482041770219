import React, { MouseEventHandler } from "react";
import "./index.scss";
import { Avatar, Button, Image } from "antd";
import { DownOutlined, UpOutlined, ToTopOutlined } from "@ant-design/icons";
import { useLocalStore, useObserver } from "mobx-react";
import { useStore } from "../../../../../common/store";
import { TokenTradeMetadata } from "../../../../../type";
import { useHistory } from "react-router-dom";
import { utils } from "../../../../../common/utils/index";
import { TradingPairIcon } from "../../../../components/TradingPairIcon/index";
import BN from "bignumber.js";
import { TokenAvatar } from "../../../../components/TokenAvatar/index";

interface Props {
  tokenMeta: TokenTradeMetadata;
}

export const LiquidityItem = (props: Props) => {
  const { tokenMeta } = props;
  const { lang, token } = useStore();
  const history = useHistory();

  const store = useLocalStore(() => ({
    expanded: false,
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    onAdd() {
      history.push(`/add-liquidity?source=iotx&dest=${tokenMeta.address}`);
    },
    onRemove() {
      history.push(`/remove-liquidity?source=iotx&dest=${tokenMeta.address}`);
    },
  }));

  return useObserver(() => (
    <div className={`component__liquidity_item ${store.expanded ? "component__liquidity_item--expanded" : ""}`}>
      <div className="component__liquidity_item__header flex items-center justify-between cursor-pointer" onClick={store.toggleExpand}>
        <span className="flex items-center text-xl font-light">
          <TradingPairIcon tokenIcon={tokenMeta.logoURI} />
          <b className="ml-2">{`${tokenMeta.symbol}/IOTX`}</b>
        </span>
        {store.expanded ? <UpOutlined /> : <DownOutlined />}
      </div>
      <div className={`component__liquidity_item__content font-medium ${store.expanded ? "component__liquidity_item__content--expanded" : ""}`}>
        <div className="flex justify-between items-center ">
          <span>
            {lang.t("pooled")} {tokenMeta.symbol}:
          </span>
          <span className="flex items-center">
            {tokenMeta.pool?.pooledTokenFormatted}&nbsp;
            <TokenAvatar src={tokenMeta.logoURI} size="small" />
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span>{lang.t("pooled")} IOTX:</span>
          <span className="flex items-center">
            {tokenMeta.pool?.pooledIotxFormatted}&nbsp;
            <Avatar src={"/image/iotx.png"} size="small" />
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span>{lang.t("your_pool_tokens")}:</span>
          <span className="flex items-center">{tokenMeta.pool?.balanceFormatted}</span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span>{lang.t("your_pool_share")}:</span>
          <span className="flex items-center">{utils.helper.number.toPrecisionFloor(new BN(tokenMeta.pool?.share).multipliedBy(100).toString(), { decimals: 4 })}%</span>
        </div>
        <Button type="text" className="component__liquidity_item__content__view_pool_info c-primary my-3" onClick={() => token.togglePoolInfoModal(tokenMeta)}>
          {lang.t("view_pool_information")}&nbsp;
          <ToTopOutlined />
        </Button>
        <div className="component__liquidity_item__content__button_group flex justify-between items-center">
          <Button type="primary" size="middle" onClick={store.onAdd}>
            {lang.t("add")}
          </Button>
          <Button type="primary" size="middle" onClick={store.onRemove}>
            {lang.t("remove")}
          </Button>
        </div>
      </div>
    </div>
  ));
};
