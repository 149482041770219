import { observable, action, computed } from "mobx";
import remotedev from "mobx-remotedev";
import { rootStore } from "./index";
import BN from "bignumber.js";
import { AntennaUtils } from "../utils/antanna";
import { utils } from "../utils/index";
import { eventBus } from "../utils/eventBus";

// @remotedev({ name: "swap" })
export class SwapStore {
  @observable recipientAddress = "";
  @observable showRecipient = false;
  @observable sourceToDestRate = true;
  @observable pendingAction = {
    actionHash: "",
  };

  @observable status: "init" | "confirm" | "waiting" | "done" = "init";

  @observable input: "source" | "dest" = "source";

  @observable source = {
    amount: "",
    token: "iotx",
  };
  @observable dest = {
    amount: "",
    token: "",
  };

  @computed
  get stats() {
    let minimunRecceived;
    if (this.input == "source") {
      minimunRecceived = this.dest.amount ? new BN(this.dest.amount).multipliedBy(1 - rootStore.setting.data.userSlippageTolerance / 10000) : new BN(0);
    } else {
      minimunRecceived = this.source.amount ? new BN(this.source.amount).multipliedBy(1 + rootStore.setting.data.userSlippageTolerance / 10000) : new BN(0);
    }
    let tradeFlow: "iotx->token" | "token->iotx" | "token->token" = "iotx->token";
    if (this.sourceTokenData.symbol == "IOTX") {
      tradeFlow = "iotx->token";
    } else {
      if (this.destTokenData.symbol == "IOTX") {
        tradeFlow = "token->iotx";
      } else {
        tradeFlow = "token->token";
      }
    }
    return {
      destSourceRate: new BN(this.source.amount || 0).dividedBy(new BN(this.dest.amount)),
      sourceDestRate: new BN(this.dest.amount || 0).dividedBy(new BN(this.source.amount)),
      liquidityProviderFee: new BN(this.source.amount || 0).multipliedBy(0.003),
      priceImpace: this.getImpact(),
      minimunRecceived: {
        raw: minimunRecceived?.multipliedBy(10 ** this.destTokenData.decimals),
        formatted: utils.helper.number.toPrecisionFloor(minimunRecceived.toFixed(), { decimals: 2 }),
      },
      tradeFlow,
    };
  }

  @computed
  get sourceTokenData() {
    const token = this.source.token;
    const tokenMeta = rootStore.token.tokens[token];
    const target = this.source;
    const amountRaw = new BN(target.amount).multipliedBy(tokenMeta?.decimals ? 10 ** tokenMeta.decimals : 1e18).toFixed();
    const amountFormatted = target.amount;
    if (token == "iotx") {
      return {
        ...tokenMeta,
        ...this.source,
        balance: rootStore.wallet.account.balanceRaw,
        allowance: rootStore.wallet.account.balanceRaw,
        symbol: "IOTX",
        decimals: 18,
        target,
        amountRaw,
        amountFormatted,
      };
    }
    return { ...tokenMeta, target, amountRaw, amountFormatted };
  }

  @computed
  get destTokenData() {
    const token = this.dest.token;
    const tokenMeta = rootStore.token.tokens[token];
    const target = this.dest;
    const amountRaw = new BN(target.amount).multipliedBy(tokenMeta?.decimals ? 10 ** tokenMeta.decimals : 1e18).toFixed();
    const amountFormatted = target.amount;

    if (token == "iotx") {
      return {
        ...tokenMeta,
        ...this.dest,
        balance: rootStore.wallet.account.balanceRaw,
        allowance: rootStore.wallet.account.balanceRaw,
        symbol: "IOTX",
        decimals: 18,
        target,
        amountRaw,
        amountFormatted,
      };
    }
    return { ...tokenMeta, target, amountRaw, amountFormatted };
  }

  @computed
  get tradeStatus() {
    if (!rootStore.wallet.account.address) {
      return {
        msg: rootStore.lang.t("home.connect_wallet"),
        connectWallet: true,
      };
    }

    const balance = Number(rootStore.wallet.account.balance);
    if (rootStore.wallet.account.balance !== "" && !isNaN(balance) && balance < 1) {
      return {
        error: true,
        msg: rootStore.lang.t("insufficient_fee"),
      };
    }

    if (this.source.amount === "") {
      return {
        error: true,
        msg: rootStore.lang.t("enter_an_amount"),
      };
    }
    if (!this.source.token || !this.dest.token) {
      return {
        error: true,
        msg: rootStore.lang.t("select_a_token"),
      };
    }
    if (this.source.amount === "" || this.dest.amount === "") {
      return {
        error: true,
        msg: rootStore.lang.t("enter_an_amount"),
      };
    }
    // let decimals = this.sourceTokenData.address != "iotx" && this.sourceTokenData?.decimals ? this.sourceTokenData?.decimals : 18;
    // if (Math.exp(-1 * decimals) > Number(this.source.amount)) {
    //   return {
    //     error: true,
    //     msg: `${this.sourceTokenData.symbol} amount >= ${Math.exp(-1 * decimals).toFixed(12)}`,
    //   };
    // }
    if (Number(this.sourceTokenData?.balance) < Number(this.sourceTokenData.amountRaw)) {
      return {
        error: true,
        msg: rootStore.lang.t("insufficient_balance", { symbol: this.sourceTokenData?.symbol }),
      };
    }
    if (new BN(this.destTokenData.amountRaw).gt(new BN(this.destTokenData.pool?.exchangeXRC20Balance)) || new BN(this.source.amount).lt(0)) {
      return {
        error: true,
        hideStat: true,
        msg: rootStore.lang.t("insufficient_liquidity"),
      };
    }

    // decimals = this.destTokenData.address != "iotx" && this.destTokenData?.decimals ? this.destTokenData?.decimals : 18;
    // if (Math.exp(-1 * decimals) > Number(this.dest.amount)) {
    //   return {
    //     error: true,
    //     msg: `${this.destTokenData.symbol} amount >= ${Math.exp(-1 * decimals).toFixed(12)}`,
    //   };
    // }

    let allownces = [];
    if (Number(this.sourceTokenData?.allowance) < Number(this.source.amount) && Number(this.source.amount) != 0) {
      allownces.push({ token: this.source.token, amount: this.source.amount });
    }
    if (allownces.length) {
      return {
        error: true,
        allownces,
        msg: rootStore.lang.t("home.swap"),
      };
    }

    return {
      ready: true,
      msg: rootStore.lang.t("home.swap"),
    };
  }

  getInputPrice(x: BN, y: BN, dx: BN): BN {
    const numerator = y.multipliedBy(997).multipliedBy(dx);
    const denominator = x.multipliedBy(1000).plus(dx.multipliedBy(997));
    return numerator.dividedToIntegerBy(denominator);
  }

  getOutputPrice(x: BN, y: BN, dy: BN): BN {
    const numerator = x.multipliedBy(dy).multipliedBy(1000);
    const denominator = y.minus(dy).multipliedBy(997);
    return numerator.dividedToIntegerBy(denominator).plus(1);
  }

  @action.bound
  reest() {
    this.source.amount = "";
    this.dest.amount = "";
  }

  @action.bound
  updateAmount(key = this.input) {
    if (key == "source") {
      // if update source token we should handle dest amount
      this.handleDestAmount(this.dest.amount);
    } else {
      // if update dest token we should hanle source amount
      this.handleSourceAmount(this.source.amount);
    }
    return this;
  }

  @action.bound
  setToken(key, val) {
    this[key].token = val;
    return this;
  }

  @action.bound
  async handleSourceAmount(val: string) {
    this.source.amount = val;
    if (!Number(val)) return;

    const dx = new BN(this.sourceTokenData.amountRaw);
    const decimal = this.destTokenData.decimals;
    if (this.source.token && this.dest.token && this.source.amount) {
      let p;
      if (this.sourceTokenData.symbol == "IOTX") {
        // iotx -> token
        p = this.getInputPrice(new BN(this.destTokenData.pool?.exchangeIotxBalance), new BN(this.destTokenData.pool?.exchangeXRC20Balance), dx);
      } else if (this.destTokenData.symbol == "IOTX") {
        // token -> IOTX
        p = this.getInputPrice(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.pool?.exchangeIotxBalance), dx);
      } else {
        // token to token swap
        // token1 -> token2, so we do token1 -> iotx and then iotx-> token2
        // token1 -> iotx
        const p1 = this.getInputPrice(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.pool?.exchangeIotxBalance), dx);

        // iotx -> token2
        p = this.getInputPrice(new BN(this.destTokenData.pool?.exchangeIotxBalance), new BN(this.destTokenData.pool?.exchangeXRC20Balance), p1);
      }

      // assuming iotx also has 18 decimals in this data.
      this.dest.amount = utils.helper.number.toPrecisionFloor(p.div(10 ** decimal).toFixed());
    } else {
      console.log("no token or amount");
    }
  }

  @action.bound
  async handleDestAmount(val: string) {
    this.dest.amount = val;
    if (!Number(val)) return;

    const dy = new BN(this.destTokenData.amountRaw);
    const decimal = this.sourceTokenData.decimals;
    if (this.source.token && this.dest.token && this.dest.amount) {
      let p;
      if (this.sourceTokenData.symbol == "IOTX") {
        p = this.getOutputPrice(new BN(this.destTokenData.pool?.exchangeIotxBalance), new BN(this.destTokenData.pool?.exchangeXRC20Balance), dy);
      } else if (this.destTokenData.symbol == "IOTX") {
        p = this.getOutputPrice(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.pool?.exchangeIotxBalance), dy);
      } else {
        // token to token

        // to get dy tokens, how much iotx needed.
        const p1 = this.getOutputPrice(new BN(this.destTokenData.pool?.exchangeIotxBalance), new BN(this.destTokenData.pool?.exchangeXRC20Balance), dy);

        // to get p1 iotx, how much source token is needed.
        p = this.getOutputPrice(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.pool?.exchangeIotxBalance), p1);
      }

      this.source.amount = utils.helper.number.toPrecisionFloor(p.div(10 ** decimal).toFixed());
    }
  }

  @action.bound
  async handleSwap() {
    let actionHash;
    const antenna = AntennaUtils.getAntenna();
    this.status = "waiting";

    const minimunRecceived = this.stats.minimunRecceived.raw.toFixed(0);

    if (utils.helper.tokenData.checkIsIotx(this.sourceTokenData)) {
      // iotx -> token
      if (this.input == "source") {
        actionHash = await this.destTokenData.exchange.iotxToTokenSwapInput({
          min_tokens: minimunRecceived,
          deadline: rootStore.setting.getActionDeadLine(),
          amount: new BN(this.source.amount).multipliedBy(1e18).toFixed(),
        });
      } else {
        const tokens_bought = this.destTokenData.amountRaw;
        actionHash = await this.destTokenData.exchange.iotxToTokenSwapOutput({ tokens_bought, deadline: rootStore.setting.getActionDeadLine(), amount: minimunRecceived });
      }
    } else {
      if (utils.helper.tokenData.checkIsIotx(this.destTokenData)) {
        // token -> iotx
        if (this.input == "source") {
          const tokens_sold = new BN(Number(this.source.amount)).multipliedBy(10 ** this.sourceTokenData.decimals).toFixed();
          actionHash = await this.sourceTokenData.exchange.tokenToIotxSwapInput({ tokens_sold, min_iotx: minimunRecceived, deadline: rootStore.setting.getActionDeadLine() });
        } else {
          const iotx_bought = this.destTokenData.amountRaw;
          actionHash = await this.sourceTokenData.exchange.tokenToIotxSwapOutput({ iotx_bought, max_tokens: minimunRecceived, deadline: rootStore.setting.getActionDeadLine() });
        }
      } else {
        // token -> token

        if (this.input == "source") {
          const tokens_sold = new BN(Number(this.source.amount)).multipliedBy(10 ** this.sourceTokenData.decimals).toFixed();
          const min_tokens_bought = minimunRecceived;
          const min_iotx_bought = this.getInputPrice(new BN(this.destTokenData.pool?.exchangeXRC20Balance), new BN(this.destTokenData.pool?.exchangeIotxBalance), new BN(min_tokens_bought)).toFixed(0);

          actionHash = await this.sourceTokenData.exchange.tokenToTokenSwapInput({
            min_iotx_bought,
            min_tokens_bought,
            token_addr: this.dest.token,
            tokens_sold,
            deadline: rootStore.setting.getActionDeadLine(),
          });
        } else {
          const tokens_bought = new BN(Number(this.dest.amount)).multipliedBy(10 ** this.destTokenData.decimals).toFixed();
          const max_tokens_sold = minimunRecceived;
          const max_iotx_sold = this.getInputPrice(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.pool?.exchangeIotxBalance), new BN(max_tokens_sold)).toFixed();

          actionHash = await this.sourceTokenData.exchange.tokenToTokenSwapOutput({
            max_iotx_sold,
            max_tokens_sold,
            tokens_bought,
            token_addr: this.dest.token,
            deadline: rootStore.setting.getActionDeadLine(),
          });
        }
      }
    }

    this.status = "done";
    rootStore.token.setActionData({
      actionHash,
      data: {
        actionHash,
        status: "init",
        type: "swap",
        summary: `Swap ${this.source.amount} ${this.sourceTokenData.symbol} for ${this.dest.amount} ${this.destTokenData.symbol}`,
        source: {
          token: this.source.token,
          amount: this.source.amount,
        },
        dest: {
          token: this.dest.token,
          amount: this.dest.amount,
        },
        from: antenna.iotx.accounts[0].address,
        addedTime: Date.now(),
      },
    });
    this.dest.amount = "";
    this.source.amount = "";

    this.pendingAction.actionHash = actionHash;
    eventBus.emit("client.swap.onSwap", { actionHash });
  }

  // get price impact based on x and dx (pool source and input source)
  getInputPriceImpact(x: BN, dx: BN): BN {
    //  (1000x)^2 / (1000 x + 997 dx)^2 - 1

    const numerator = x.multipliedBy(1000).pow(2);
    const denominator = x.multipliedBy(1000).plus(dx.multipliedBy(997)).pow(2);
    if (denominator.isEqualTo(0)) {
      return new BN(0);
    }
    return numerator.dividedBy(denominator).minus(1);
  }

  // get price impact based on y and dy (pool dest and input dest)
  getOutputPriceImpact(y: BN, dy: BN): BN {
    // (y - dy)^2 / y^2 - 1

    const numerator = y.minus(dy).pow(2);
    const denominator = y.pow(2);
    if (y.isEqualTo(0)) {
      return new BN(0);
    }

    return numerator.dividedBy(denominator).minus(1);
  }

  // format price impact
  // input is a negative number. the raw number
  // output is positive percentage
  getFormattedPriceImpact(p: BN): string {
    return utils.helper.number.toPrecisionFloor(p.abs().multipliedBy(100).toString());
  }

  getImpact(): number | string {
    if (!this.source.amount || !this.source.token || !this.dest.token || !this.dest.amount) return "";
    if (this.sourceTokenData.symbol == "IOTX") {
      // from iotx to token
      return this.getFormattedPriceImpact(this.getInputPriceImpact(new BN(this.destTokenData.pool?.exchangeIotxBalance), new BN(this.sourceTokenData.amountRaw)));
    } else if (this.destTokenData.symbol == "IOTX") {
      // from token to iotx
      return this.getFormattedPriceImpact(this.getInputPriceImpact(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.amountRaw)));
    } else {
      // from token to token
      const impact1 = this.getInputPriceImpact(new BN(this.sourceTokenData.pool?.exchangeXRC20Balance), new BN(this.sourceTokenData.amountRaw));
      const impact2 = this.getOutputPriceImpact(new BN(this.destTokenData.pool?.exchangeXRC20Balance), new BN(this.destTokenData.amountRaw));

      return this.getFormattedPriceImpact(impact1.multipliedBy(impact2).plus(impact1).plus(impact2));
    }
  }
}
