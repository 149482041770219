import React, { useState } from "react";
import { Modal, Button, Radio } from "antd";
import { observer, useLocalStore } from "mobx-react-lite";
import { useStore } from "../../../common/store/index";
import { css } from "../../../common/utils/stitches.config";
import { Connector } from "../../../type";

const ConnectorModal = observer(() => {
  const { lang, wallet } = useStore();
  const store = useLocalStore(() => ({
    connector: "",
    close() {
      wallet.setShowConnector(false);
    },
    change(val) {
      wallet.activeConnector(val);
      wallet.setShowConnector(false);
    }
  }));

  return (
    <Modal footer={false} bodyStyle={{ background: "transparent", padding: 0 }} width={400} closable={false} getContainer={false} onCancel={store.close} visible={wallet.showConnector}>
      <div className={styles.contentStyle}>
        <div className="modal-header">
          <span>{lang.t("connect_a_wallet")}</span>
          <button onClick={store.close}>
            <img src={"/image/close.svg"} alt="" />
          </button>
        </div>
        <Radio.Group onChange={e => store.change(e.target.value)} value={store.connector}>
          <Radio.Button value={Connector.IopayDesktop}>
            <div className="radio-item">
              <span>ioPay Desktop</span>
            </div>
          </Radio.Button>
          {/* <Radio.Button value={Connector.IopayExtension}>
            <div className="radio-item">
              <span>iopay Extension</span>
            </div>
          </Radio.Button> */}
        </Radio.Group>
        <div className="modal-footer">
          <span>New to ioPay? </span>
          <a href="https://iopay.iotex.io/" className="link">
            {lang.t("learn_wallets")}
          </a>
        </div>
      </div>
    </Modal>
  );
});

export default ConnectorModal;

const styles = {
  modelStyle: {
    backgroundColor: "transparent !important",
    padding: 0,
    width: "initial !important",
    display: "inline-block"
  },

  contentStyle: css({
    backgroundColor: "$bg",
    borderRadius: "6px",
    padding: "0 20px",
    color: "white",
    fontMono: 400,
    overflow: "hidden",
    width: 400,

    ".ant-radio-group": {
      display: "block"
    },

    ".ant-radio-button-wrapper": {
      display: "block",
      height: "auto",
      padding: 0,
      boxSizing: "content-box",
      border: "1px solid transparent",
      background: "initial",

      ":first-child, last-child": {
        borderRadius: 0,
        border: "none"
      },

      ":not(:first-child)::before": {
        width: 0
      },

      ":first-child": {
        marginTop: 20
      },

      ":not(:first-child)": {
        marginTop: 13
      }
    },

    ".ant-radio-button-wrapper-checked:hover": {
      borderColor: "$greenA400",
      boxShadow: "initial"
    },

    ".ant-radio-button-wrapper:focus-within": {
      border: "1px solid $greenA400",
      boxSizing: "content-box"
    },

    ".ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child": {
      borderColor: "$greenA400"
    },

    ".ant-radio-button-wrapper-checked": {
      borderColor: "$greenA400"
    },

    ".modal-header": {
      fontSize: "14px",
      padding: "18px 0",
      flexBetweenCenter: "row",
      borderBottom: "1px solid $gray800",

      button: {
        border: "none",
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        outline: "none"
      },

      img: {
        size: 20,
        cursor: "pointer"
      }
    },

    ".radio-item": {
      flexBetweenCenter: "row",
      height: "64px",
      lineHeight: "64upx",
      backgroundColor: "$bg3",
      padding: "0 18px",
      fontSize: 16,
      color: "white",
      fontMono: 400,

      img: {
        size: 28
      }
    },

    ".modal-footer": {
      padding: "28px 0",
      marginTop: 20,
      fontSize: 12,
      borderTop: "1px solid $gray800",

      ".link": {
        color: "$greenA400",
        textDecoration: "underline",
        cursor: "pointer"
      }
    }
  })
};
