import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Button, Avatar, Tooltip } from "antd";
import {
  ArrowLeftOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TokenInputField } from "../../components/TokenInputField";
import { AddLiquidityConfirmModal } from "./components/AddLiquidityConfirmModal";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useStore } from "../../../common/store/index";
import { TokenMetadata } from "../../../type";
import { AddLiquiditySubmittedModal } from "./components/AddLiquiditySubmittedModal";
import { useRouter } from "../../utils/react-hooks";
import { BlueCard, TextWrapper } from "../../components/Card/index";
import { AutoColumn } from "../../components/Column/index";
import { AppLayout } from "../../layouts/AppLayout/index";
import { hooks } from "../../utils/hooks";
import { TradingPairIcon } from "../../components/TradingPairIcon/index";

export const LiquidityAdd = () => {
  const { wallet, token: tokenStore, addLiquidity, lang } = useStore();
  const router = useRouter();

  const store = useLocalStore(() => ({
    async selectToken(key: "source" | "dest", val: Partial<TokenMetadata>) {
      addLiquidity[key].token = val.address;

      await tokenStore.selectToken({ address: val.address });
      if (key === "dest") {
        const token = tokenStore.tokens[val.address];
        tokenStore.loadTokenPool(token);
      }

      if (addLiquidity[key]) {
        addLiquidity.handleAmount({
          key: key == "source" ? "dest" : "source",
          val:
            key === "source"
              ? addLiquidity.dest.amount
              : addLiquidity.source.amount,
        });
      }
    },
    onConfirm() {
      if (addLiquidity.tradeStatus.connectWallet) {
        wallet.connectWallet();
        return;
      }
      if (!addLiquidity.tradeStatus.error) {
        if (addLiquidity.tradeStatus.noExchange) {
          addLiquidity.handleCreateExchange();
        } else {
          addLiquidity.status = "confirm";
        }
      }
    },
    onBack() {
      router.history.push("/pool");
    },
    handleParams() {
      store.selectToken("source", { address: router.query.source || "iotx" });
      if (router.query.dest) {
        store.selectToken("dest", { address: router.query.dest });
      }
    },
  }));
  useEffect(() => {
    hooks.waitInit().then(() => store.handleParams());
    hooks.waitAccount().then(() => store.handleParams());
    return () => {
      addLiquidity.reset();
    };
  }, []);
  return useObserver(() => (
    <AppLayout>
      <div className="page__liquidity_add app-frame-content ">
        <div className="page__liquidity_add__content app-box-shadow app-frame bg-white p-3 rounded-lg">
          <div className="page__liquidity_add__content__header flex justify-between items-center my-2 c-gray-20">
            <Button
              type="text"
              shape="circle"
              icon={<ArrowLeftOutlined className="c-gray-10" />}
              onClick={store.onBack}
            />
            <span className="text-xl font-medium">
              {lang.t("add_liquidity")}
            </span>
            <Tooltip placement="leftTop" title={lang.t("add_liquidity_help")}>
              <Button
                type="text"
                shape="circle"
                icon={<QuestionCircleOutlined className="c-gray-10" />}
              />
            </Tooltip>
          </div>
          {addLiquidity.tradeStatus.noLiquidity && (
            <BlueCard>
              <AutoColumn gap="10px">
                <TextWrapper fontWeight={600} color={"#666"}>
                  {addLiquidity.tradeStatus.noExchange &&
                    lang.t("add_liquidity_no_exchange")}
                  {!addLiquidity.tradeStatus.noExchange &&
                    addLiquidity.tradeStatus.noLiquidity &&
                    lang.t("add_liquidity_no_liquidity")}
                </TextWrapper>
              </AutoColumn>
            </BlueCard>
          )}
          <div className="page__liquidity_add__content__main mt-4">
            <TokenInputField
              label={lang.t("input")}
              amount={
                addLiquidity.input == "source"
                  ? addLiquidity.source.amount
                  : addLiquidity.sourceTokenData.amountFormatted
              }
              token={addLiquidity.source.token}
              onChangeAmount={(val) =>
                addLiquidity.handleAmount({ key: "source", val })
              }
              onChangeToken={(e) => store.selectToken("source", e)}
              loading={addLiquidity.sourceTokenData.state?.loading || false}
              showMax
              showCommonBases
              selectable={false}
              blackList={[addLiquidity.dest.token]}
            />
            <div className="text-center my-2">
              <PlusOutlined />
            </div>
            <TokenInputField
              showCommonBases
              label={lang.t("input")}
              amount={
                addLiquidity.input == "dest"
                  ? addLiquidity.dest.amount
                  : addLiquidity.destTokenData.amountFormatted
              }
              token={addLiquidity.dest.token}
              onChangeAmount={(val) =>
                addLiquidity.handleAmount({ key: "dest", val })
              }
              onChangeToken={(e) => store.selectToken("dest", e)}
              loading={addLiquidity.destTokenData.state?.loading || false}
              blackList={["iotx"]}
              showMax
            />
            {addLiquidity.destTokenData.priceToIotx &&
              !addLiquidity.destTokenData.pool?.noExchange &&
              addLiquidity.source.amount &&
              addLiquidity.dest.amount && (
                <div className="page__liquidity_add__content__main__pool_and_share mt-4">
                  <div className="page__liquidity_add__content__main__pool_and_share__label c-gray-20">
                    {lang.t("price_and_pool_share")}
                  </div>
                  <div className="page__liquidity_add__content__main__pool_and_share__content p-2 rounded-lg flex items-center mt-2">
                    <div className="text-center flex-1">
                      <div className="font-semibold text-base">
                        {addLiquidity.stats.sourceDestRate}
                      </div>
                      <div className="c-grey1">{`${addLiquidity.destTokenData?.symbol} per ${addLiquidity.sourceTokenData?.symbol}`}</div>
                    </div>
                    <div className="text-center flex-1">
                      <div className="font-semibold text-base">
                        {addLiquidity.stats.destSourceRate}
                      </div>
                      <div className="c-grey1">{`${addLiquidity.sourceTokenData?.symbol} per ${addLiquidity.destTokenData?.symbol}`}</div>
                    </div>
                    <div className="text-center flex-1">
                      <div className="font-semibold text-base">
                        {addLiquidity.stats.shareOfPool}%
                      </div>
                      <div className="c-grey1">{lang.t("share_of_pool")}</div>
                    </div>
                  </div>
                </div>
              )}
            {addLiquidity.tradeStatus.allownces &&
              addLiquidity.tradeStatus.allownces.map(({ token, amount }) => {
                const tokenData = tokenStore.tokens[token];
                return (
                  <Button
                    key={token}
                    onClick={(e) => tokenStore.approve({ token, amount })}
                    type="primary"
                    className="page__liquidity_add__content__main__approve text-center w-full mt-4"
                    size="large"
                  >
                    {lang.t("approve")} {tokenData.symbol}
                  </Button>
                );
              })}
            <Button
              onClick={store.onConfirm}
              type="primary"
              className={`page__liquidity_add__content__main__suply w-full mt-4 ${
                addLiquidity.tradeStatus.error ? "btn--disabled" : ""
              }`}
              size="large"
              disabled={addLiquidity.tradeStatus.error}
            >
              {addLiquidity.tradeStatus.msg}
            </Button>
          </div>
          {addLiquidity.dest.token ? (
            <div className="page__liquidity_add__content__position app-box-shadow bg-white rounded p-3 px-5 mb-4">
              <div className="c-gray-20">{lang.t("your_position")}</div>
              <div className="flex items-center text-lg justify-between mt-2">
                <div className="flex items-center">
                  <TradingPairIcon
                    tokenIcon={addLiquidity.destTokenData.logoURI}
                  />
                  &nbsp;
                  <span>{addLiquidity.destTokenData.symbol}/IOTX</span>
                </div>
                <span>{addLiquidity.destTokenData.pool?.balanceFormatted}</span>
              </div>
              <div className="flex justify-between items-center c-gray-10 text-sm mt-4 font-light">
                <span>IOTX:</span>
                <span>
                  {addLiquidity.destTokenData.pool?.pooledIotxFormatted}
                </span>
              </div>
              <div className="flex justify-between items-center c-gray-10 text-sm mt-4 mb-4 font-light">
                <span>{addLiquidity.destTokenData.symbol}:</span>
                <span>
                  {addLiquidity.destTokenData.pool?.pooledTokenFormatted}
                </span>
              </div>
            </div>
          ) : (
            <div className="page__liquidity_add__content__position app-box-shadow bg-white rounded-lg p-5 mb-4 text-center">
              <img
                className="inline-block w-5 mr-2"
                src="/image/icon_tips_add_liquidity.png"
                alt=""
              />
              {lang.t("add_liquidity_tips")}
            </div>
          )}
        </div>

        <AddLiquidityConfirmModal />
        <AddLiquiditySubmittedModal />
      </div>
    </AppLayout>
  ));
};
