import React, { MouseEventHandler, useEffect, useMemo } from "react";
import { Modal, Button, Input, Divider, Spin, Tooltip, Image } from "antd";
import { QuestionCircleOutlined, ArrowDownOutlined, LoadingOutlined, WarningFilled } from "@ant-design/icons";
import { useStore } from "../../../common/store";
import Avatar from "antd/lib/avatar/avatar";
import { TokenTradeMetadata } from "../../../type";
import { observer, useLocalStore, useObserver } from "mobx-react-lite";
import { validateAddress } from "iotex-antenna/lib/account/utils";
import { XRC20 } from "iotex-antenna/lib/token/xrc20";
import { AntennaUtils } from "../../../common/utils/antanna";
import { utils } from "../../../common/utils/index";
import { hooks } from "../../utils/hooks";
import { TokenAvatar } from "../TokenAvatar/index";
import { helper } from "../../../common/utils/helper";
import { toJS } from "mobx";

interface IComponentProps {
  modalOpen: boolean;
  toggleModal: MouseEventHandler;
  onChange: Function;
  showCommonBases: boolean;
  value?: string;
  blackList?: string[];
}

export const TokenSelectModal = observer((props: IComponentProps) => {
  const { lang, token, setting, wallet } = useStore();
  const { modalOpen, toggleModal, onChange, showCommonBases } = props;

  const store = useLocalStore(
    props => ({
      keyword: "",
      newTokens: {} as { [key: string]: Partial<TokenTradeMetadata> },
      async setKeyword(value) {
        this.keyword = value;
        if (validateAddress(value)) {
          if (token.tokens[value]) {
            return;
          }
          const xrc20 = new XRC20(value, { provider: AntennaUtils.getAntenna().iotx, signer: AntennaUtils.getAntenna().iotx.signer });
          const [symbol, decimals] = await Promise.all([xrc20.symbol(), xrc20.decimals()]);
          console.log({ symbol, decimals });
          if (symbol && decimals) {
            store.newTokens[value] = {
              address: value,
              symbol: symbol.toString(),
              decimals: decimals.toNumber(),
              logoURI: utils.helper.img.defaultImgUrl(),
              state: {
                loading: false,
                external: true,
                saved: false
              }
            };
          }
        }
      },

      handleCustomToken(_token: Partial<TokenTradeMetadata>) {
        if (_token.state.saved) {
          setting.removeTokens(_token);
        } else {
          store.selectToken({ ..._token });
          setting.setToken(_token);
        }
      },
      isDisabled(common: Partial<TokenTradeMetadata>) {
        if (props.blackList.includes(common.address)) return true;
        //@ts-ignore
        if (utils.helper.tokenData.checkIsIotx(common.address)) return false;
        return common.address === props.value;
      },

      selectToken(_token: Partial<TokenTradeMetadata>) {
        if (store.isDisabled(_token)) return;
        if (_token.state.external) {
          if (!token.tokens[_token.address]) {
            token.addExternalToken(_token);
          }
        }
        const tokenUIStatus = helper.tokenData.checkTokenStatusType(_token);
        console.log(tokenUIStatus);
        if (tokenUIStatus == "self_listed" || tokenUIStatus == "found_by_address") {
          Modal.confirm({
            title: lang.t("warning"),
            content: lang.t("external_token_warning"),
            okText: lang.t("i_understand")
          });
        }
        store.onChangeToken(_token);
      },
      onChangeToken(_token: Partial<TokenTradeMetadata>) {
        onChange(_token);
        store.keyword = "";
        toggleModal(null);
      },

      tokenMetadata(keyword: string, loading?: boolean) {
        if (keyword === "") {
          return loading ? Object.values(token.tokens) : Object.values(token.tokens).sort(utils.helper.exchange.compareLiquidityFn);
        }
        if (store.newTokens[keyword]) return [store.newTokens[keyword]];
        if (token.tokens[keyword]) return [token.tokens[keyword]];
        const list = Object.values(token.tokens).filter(common => common.symbol?.toLowerCase().includes(this.keyword));
        return loading ? list : list.sort(utils.helper.exchange.compareLiquidityFn);
      },
      getListLabelText(token: Partial<TokenTradeMetadata>) {
        const tokenUIState = utils.helper.tokenData.checkTokenStatusType(token);
        if (tokenUIState == "normal") return;
        return lang.t(tokenUIState);
      },
      get tokenMetaList() {
        const tokenMetadata = store.tokenMetadata(store.keyword, token.isLoadingPool);
        return tokenMetadata || [];
      }
    }),
    props
  );

  const cancelModalFun = () => {
    store.keyword = "";
    toggleModal(null);
  };

  useEffect(() => {
    if (modalOpen) {
      hooks.waitAccount().then(() => {
        token.batchLoadXRC20AccountData();
      });
      const tokenValues = Object.values(token.tokens);
      if (token.tokens && tokenValues.length > 0 && !tokenValues[tokenValues.length - 1].pool?.exchangeIotxBalance) {
        token.loadPool();
      }
    }
  }, [modalOpen]);

  const tokenList = useObserver(() => {
    if (token.isLoadingPool) {
      return (
        <div className="flex items-center flex-wrap">
          <Spin indicator={<LoadingOutlined />} />
        </div>
      );
    }
    return (
      <div className="flex items-center flex-wrap">
        {store.tokenMetadata(store.keyword).map((common, index) => (
          <span
            className={`flex items-center mr-2 mt-1 bg-lightGrey rounded px-2 py-1 ${store.isDisabled(common) ? "cursor-not-allowed opacity-75" : "cursor-pointer"}`}
            key={index}
            onClick={e => store.selectToken(common)}
          >
            <TokenAvatar src={common.logoURI} size="small" />
            &nbsp;{common.symbol}
          </span>
        ))}
      </div>
    );
  });

  return (
    <Modal
      visible={modalOpen}
      className="modal__token__select"
      onCancel={cancelModalFun}
      width={420}
      title={
        <span className="c-gray flex items-center">
          {lang.t("token_select_modal.select_a_token")}&nbsp;&nbsp;
          <Tooltip placement="bottom" title={lang.t("token_select_modal.select_a_token_tips")} trigger="hover">
            <QuestionCircleOutlined className="c-gray-10" />
          </Tooltip>
        </span>
      }
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Input placeholder={lang.t("search_input")} className="c-gray-10" value={store.keyword} onChange={event => store.setKeyword(event.target.value)} />
      {showCommonBases && (
        <>
          <div className="flex items-center  c-grey mt-6 mb-2">
            <span>{lang.t("common_bases")}</span> &nbsp;
            <QuestionCircleOutlined />
          </div>
          {tokenList}
        </>
      )}
      <div className="flex items-center justify-between c-gray mt-4 mb-4 text-sm">
        <span>{lang.t("token_select_modal.token_name")}</span>
        <ArrowDownOutlined />
      </div>
      <div className="overflow-y-auto" style={{ height: "24rem" }}>
        {token.isLoadingPool && <Spin indicator={<LoadingOutlined />} />}
        <div>
          {store.tokenMetaList.map((common, index) => (
            <div
              className={`flex items-center justify-between c-gray-10 mb-4 ${store.isDisabled(common) ? "cursor-not-allowed opacity-75" : "cursor-pointer"}`}
              key={index}
              onClick={e => store.selectToken(common)}
            >
              <div className="flex items-center text-base font-light">
                <TokenAvatar src={common.logoURI} key={common.address} />
                <div className="ml-2">
                  <div>{common.symbol}</div>
                  {common.state?.external && (
                    <div className="text-sm flex items-center">
                      {common.state.warning && <WarningFilled className="mr-1" />}
                      <span>{store.getListLabelText(common)} </span>
                      <span
                        className="ml-2 text-blue-500 hover:underline"
                        onClick={e => {
                          e.stopPropagation();
                          store.handleCustomToken(common);
                        }}
                      >{`(${lang.t(common.state.saved ? "remove" : "add")})`}</span>
                    </div>
                  )}
                </div>
              </div>
              {common.state.loading ? <Spin indicator={<LoadingOutlined />} /> : <div>{utils.helper.tokenData.checkIsIotx(common) ? wallet.state.balance : common.balanceFormatted}</div>}
            </div>
          ))}
        </div>
      </div>
      <Divider />
      {/* <Button type="text" className="c-gray-10 text-center w-full">
        {lang.t("token_select_modal.having_trouble_finding_a_token")}
      </Button> */}
    </Modal>
  );
});
