import Antenna from "iotex-antenna";
import { WsSignerPlugin } from "./ws-plugin";
import { utils } from "./index";
import { publicConfig } from "../../../configs/public";
import { IAccount } from "iotex-antenna/lib/account/account";
import { JsBridgeSignerMobile } from "./js-plugin";
import { eventBus } from "./eventBus";
import { _ } from "./lodash";
import { Connector } from "../../type";

export class AntennaUtils {
  public static defaultContractOptions = {
    gasLimit: "1000000",
    gasPrice: "1000000000000"
  };
  public static antenna: Antenna | null = null;
  public static wsSigner: WsSignerPlugin | null = null;
  public static injectSigner = null;
  public static jsSigner = null;
  public static connector: Connector | string = null;

  public static get account() {
    return this.antenna?.iotx?.accounts[0];
  }

  static getAntenna(): Antenna {
    if (!utils.env.isSSR()) {
      if (!this.connector) {
        this.connector = localStorage.getItem("connector");
      }
    }
    if (this.antenna) {
      return this.antenna;
    }
    if (this.connector == Connector.IopayExtension) {
      //@ts-ignore
      this.injectSigner = window.antennaSigner;
      const antenna = new Antenna(publicConfig.IOTEX_CORE_ENDPOPINT, {
        signer: this.injectSigner
      });
      this.antenna = antenna;
      return antenna;
    }
    if (this.connector == Connector.IopayDesktop) {
      this.wsSigner = new WsSignerPlugin({
        options: {
          //@ts-ignore
          packMessage: data => JSON.stringify(data),
          //@ts-ignore
          unpackMessage: data => JSON.parse(data),
          attachRequestId: (data, requestId) => Object.assign({ reqId: requestId }, data),
          extractRequestId: data => data && data.reqId
        }
      });
      const antenna = new Antenna(publicConfig.IOTEX_CORE_ENDPOPINT, {
        signer: this.wsSigner.start()
      });
      //@ts-ignore
      this.antenna = antenna;
      return antenna;
    }
    if (utils.env.isIoPayMobile()) {
      this.jsSigner = new JsBridgeSignerMobile();
      const antenna = new Antenna(publicConfig.IOTEX_CORE_ENDPOPINT, {
        signer: this.jsSigner
      });
      //@ts-ignore
      this.antenna = antenna;
      return antenna;
    }

    const antenna = new Antenna(publicConfig.IOTEX_CORE_ENDPOPINT);
    //@ts-ignore
    this.antenna = antenna;
    return antenna;
  }

  static async getIotxBalance(address: string): Promise<number> {
    const antenna = AntennaUtils.getAntenna();
    const { accountMeta } = await antenna.iotx.getAccount({ address });
    // @ts-ignore
    return Number(fromRau(accountMeta.balance, "Iotx"));
  }

  static async signMessage(message: string): Promise<string> {
    const antenna = AntennaUtils.getAntenna();
    if (antenna.iotx.signer && antenna.iotx.signer.signMessage) {
      const signed = await antenna.iotx.signer.signMessage(message);
      if (typeof signed === "object") {
        return Buffer.from(signed).toString("hex");
      }
      return signed;
    }
    const account = antenna.iotx.accounts[0];
    const sig = account && (await account.sign(message));
    return (sig && sig.toString("hex")) || "";
  }
}
