import React from "react";
import { Modal, Spin, notification, Avatar } from "antd";
import { useStore } from "../../../common/store";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { utils } from "../../../common/utils";
import { LoadingOutlined, CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { publicConfig } from "../../../../configs/public";

interface IComponentProps {}

export const AccountChangeModal = (props: IComponentProps) => {
  const { wallet, lang, token: tokenStore } = useStore();

  const onCopyAddress = () => {
    copy(wallet.account.address);
    notification.open({
      message: "Copied",
      description: utils.helper.string.truncate(wallet.account.address, 12),
      duration: 1,
    });
  };

  return useObserver(
    () =>
      wallet.account && (
        <Modal
          visible={wallet.showAccountChangeModal}
          className="modal__account_change"
          onCancel={wallet.toggleAccountChangeModal}
          width={410}
          title={<span className="c-gray-10">{lang.t("account")}</span>}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <div>
            <div className="modal__account_change__account_item border-white-20 py-3 px-4 m-4 mt-0 rounded">
              <div className="flex justify-between items-center c-gray font-light">
                <span>{lang.t("connected_with_iopay")}</span>
              </div>
              <div className="text-xs c-gray-30 mt-2 mb-1 break-all whitespace-normal">
                <Avatar src="/image/iotx.png" size="small" />
                &nbsp;&nbsp;
                {wallet.account.address}
              </div>
              <div className="flex items-center c-gray-20 px-1 text-sm font-light">
                <div className="flex items-center cursor-pointer" onClick={onCopyAddress}>
                  <img src="/image/icon_copy.png" className="w-6" />
                  &nbsp;{lang.t("copy_address")}
                </div>

                <a
                  className="modal__account_change__account_item__view_on_iotexscan flex items-center ml-3 cursor-pointer"
                  href={`${publicConfig.IOTEXSCAN_ENDPOINT}/address/${wallet.account.address}`}
                  target="_blank"
                >
                  <img src="/image/icon_view_on_iotexscan.png" className="w-6" />
                  &nbsp;<span>{lang.t("view_on_iotexscan")}</span>
                </a>
              </div>
            </div>
            <div className="modal__account_change__transactions p-4 bg-white-30">
              {tokenStore.actionHistory.length ? (
                <div className="mt-2">
                  <div className="text-base flex items-center justify-between">
                    <span className="c-gray-20">{lang.t("recent_transactions")}</span>
                    <span className="c-third cursor-pointer font-light" onClick={tokenStore.clearActions}>
                      {lang.t("clear_all")}
                    </span>
                  </div>
                  {tokenStore.actionHistory.map((transaction) => (
                    <div key={transaction.actionHash} className="mt-3 text-sm c-third flex justify-between items-center font-light">
                      <a href={`${publicConfig.IOTEXSCAN_ENDPOINT}/action/${transaction.actionHash}`} target="_blank" className="c-third">
                        {transaction.summary}
                      </a>
                      {transaction.status == "success" && <CheckCircleOutlined />}
                      {transaction.status == "failed" && <InfoCircleOutlined className="c-warn-1" />}
                      {transaction.status == "init" && <Spin indicator={<LoadingOutlined />} />}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-2 c-gray-10 text-sm font-light">{lang.t("your_transactions_will_appear_here")}</div>
              )}
            </div>
          </div>
        </Modal>
      )
  );
};
