import { observable, action, computed, toJS } from "mobx";
import { TokenTradeMetadata, ActionHistory } from "../../type";
import { publicConfig } from "../../../configs/public";
import { AntennaUtils } from "../utils/antanna";
import { XRC20 } from "iotex-antenna/lib/token/xrc20";
import { ExchangeContract } from "../contracts/exchange";
import { rootStore } from "./index";
import BN from "bignumber.js";
import { _ } from "../utils/lodash";
import { utils } from "../utils/index";
import Queue from "p-queue";
import { eventBus } from "../utils/eventBus";
import { fromRau, validateAddress } from "iotex-antenna/lib/account/utils";
import { contracts } from "../contracts/index";
import { analyticsClient } from "../utils/gql";

export class TokenStore {
  constructor() {
    utils.env.onBrowser(() => {});
  }

  @observable poolInfoModalOpen = false;
  @observable poolInfoToken: Partial<TokenTradeMetadata> = null;

  @action.bound
  togglePoolInfoModal(tokenMeta?) {
    if (tokenMeta) {
      this.poolInfoToken = tokenMeta;
    }
    this.poolInfoModalOpen = !this.poolInfoModalOpen;
  }

  @observable tokens: {
    [key: string]: TokenTradeMetadata;
  } = {};

  @observable actions: {
    [key: string]: {
      [key: string]: ActionHistory;
    };
  } = {};
  checkActionHistoryTiemr: any = null;

  @computed
  get tokensWithLiquidity() {
    return Object.values(this.tokens).filter(i => i.pool && Number(i.pool?.balance));
  }

  @computed
  get actionHistory() {
    return Object.values(this.actions[publicConfig.IOTEX_CORE_ENDPOPINT] || {});
  }

  @computed
  get pendingActions() {
    return this.actionHistory.filter(i => i.status == "init");
  }

  checkHistoryInterval = 5000;

  async init() {
    this.initEvent();
    this.initCheckActionHistory();
    this.initTokens();
    this.initRemoteToken();
  }

  initCheckActionHistory() {
    const actions = window.localStorage.getItem("token_actions");
    if (actions) {
      this.actions = JSON.parse(actions);
    }
    this.checkActionHistory();

    if (this.checkActionHistoryTiemr) {
      clearInterval(this.checkActionHistoryTiemr);
    }
    setInterval(() => {
      this.checkActionHistory();
    }, this.checkHistoryInterval);
  }

  @action.bound
  initTokens() {
    const token = rootStore.setting.state.tokens || {};
    const metadatas = [...Object.values(token), ...publicConfig.tokenMetadata];
    //@ts-ignore
    this.tokens = metadatas.reduce((p, c: TokenTradeMetadata, i) => {
      p[c.address] = this.initToken({ ...c });
      return p;
    }, {});
  }

  @action.bound
  async initRemoteToken() {
    const tokens = await analyticsClient.chain.query.exchanges({ pagination: { first: 50, skip: 0 } }).execute({
      balanceOfIOTX: 1,
      token: {
        address: 1,
        name: 1,
        symbol: 1,
        decimals: 1
      }
    });
    if (tokens) {
      tokens
        .filter(i => !this.tokens[i.token.address])
        .filter(i => Number(i.balanceOfIOTX) * 2 >= Number(publicConfig.COIN_LIST_LIMIT) * 1e18)
        .forEach(i => {
          //@ts-ignore
          this.tokens[i.token.address] = this.initToken({
            address: i.token.address,
            symbol: i.token.symbol,
            decimals: i.token.decimals,
            state: {
              external: true,
              loading: false,
              saved: false,
              warning: true
            }
          });
        });
    }
  }

  initToken(token: TokenTradeMetadata) {
    if (token.address) {
      token.xrc20 = new XRC20(token.address, { provider: AntennaUtils.getAntenna().iotx, signer: AntennaUtils.getAntenna().iotx.signer });
    }
    if (!token.logoURI) {
      token.logoURI = utils.helper.img.assetsImageUrl(token.address);
    }
    token.pool = Object.create({});
    if (!token.state) {
      token.state = {
        loading: false,
        external: false,
        saved: false
      };
    }

    return token;
  }

  @action.bound
  addExternalToken(token: Partial<TokenTradeMetadata>) {
    //@ts-ignore
    const externalToken = this.initToken(token);
    externalToken.state.external = true;
    //@ts-ignore
    this.tokens = {
      [token.address]: externalToken,
      ...this.tokens
    };
  }

  @action.bound
  removeTokens(token: Partial<TokenTradeMetadata>) {
    if (!this.tokens[token.address]) return;
    delete rootStore.token.tokens[token.address];
    //@ts-ignore
    eventBus.emit("client.token.removeToken", token);
  }

  initEvent() {
    eventBus
      .on("client.addLiquidity.onSupply", () => {
        setTimeout(() => {
          this.checkActionHistory();
        }, 11000);
      })
      .on("client.swap.onSwap", () => {
        setTimeout(() => {
          this.checkActionHistory();
        }, 11000);
      })
      .on("client.action.success", async action => {
        if (action.type == "approve") {
          const source = this.tokens[action.source.token];
          await Promise.all([this.loadToken(source)]);
        }
        if (action.type == "swap") {
          const [source, dest] = [this.tokens[action.source.token], this.tokens[action.dest.token]];

          await Promise.all([rootStore.wallet.loadAccount(), this.loadToken(source), this.loadToken(dest)]);
          rootStore.swap.updateAmount();
        }
        if (["addLiquidity", "createExchange"].includes(action.type)) {
          const [source, dest] = [this.tokens[action.source.token], this.tokens[action.dest.token]];
          rootStore.addLiquidity.reset();
          await Promise.all([rootStore.wallet.loadAccount(), this.loadToken(source), this.loadToken(dest)]);
        }
        if (action.type == "removeLiquidity") {
          const [source, dest] = [this.tokens[action.source.token], this.tokens[action.dest.token]];
          rootStore.removeLiquidity.reset();
          await Promise.all([rootStore.wallet.loadAccount(), this.loadToken(source), this.loadToken(dest)]);
        }
        if (action.type == "wrap") {
          await Promise.all([rootStore.wallet.loadAccount(), rootStore.wiotx.loadWIotxSymbol()]);
          await rootStore.wiotx.refreshWIotxBalance();
        }
      })
      .on("client.token.removeToken", token => {
        [rootStore.swap, rootStore.addLiquidity, rootStore.removeLiquidity].forEach(i => {
          if (i.source.token == token.address) {
            i.source.token = "";
          }
          if (i.dest.token == token.address) {
            i.dest.token = "";
          }
        });
      });
  }

  storeActions() {
    window.localStorage.setItem("token_actions", JSON.stringify(this.actions));
  }

  @observable isLoadingPool = false;
  @action.bound
  async loadPool() {
    if (!this.isLoadingPool) {
      this.isLoadingPool = true;
      const queue = new Queue({ concurrency: 2 });
      _.each(this.tokens, (token, k) => {
        if (token.pool?.pooledIotx) return;
        queue.add(async () => {
          await this.loadToken(token);
        });
      });
      await queue.onIdle();
      this.isLoadingPool = false;
    }
  }

  @action.bound
  clearActions() {
    this.actions = {};
    window.localStorage.removeItem("token_actions");
  }

  @action.bound
  async loadTokenPool(token: Partial<TokenTradeMetadata>) {
    if (!token || !token.xrc20 || !token.exchange || token.pool.noExchange) return;

    const antenna = AntennaUtils.getAntenna();
    const { decimals } = this.tokens[token.address];
    let [totalSupply, _exchangeXRC20Balance, exchangeIOTXAccount] = await Promise.all([
      token.exchange.totalSupply(),
      token.xrc20.balanceOf(token.exchangeAddress),
      antenna.iotx.getAccount({ address: token.exchangeAddress })
    ]);

    const exchangeIotxBalance = exchangeIOTXAccount.accountMeta.balance;
    const priceToIotx = new BN(exchangeIotxBalance).div(_exchangeXRC20Balance).multipliedBy(10 ** token.decimals / 1e18);
    const exchangeIotxBalanceFormatted = utils.helper.number.toPrecisionFloor(new BN(exchangeIotxBalance).div(1e18).toFixed());

    const totalSupplyBN = new BN(totalSupply);
    const totalSupplyFormatted = utils.helper.number.toPrecisionFloor(totalSupplyBN.div(1e18).toFixed());
    const noLiquidity = totalSupplyBN.lte(0);

    totalSupply = totalSupplyBN.toFixed();

    const exchangeXRC20Balance = new BN(_exchangeXRC20Balance).toFixed();
    const exchangeXRC20BalanceFormatted = utils.helper.number.toPrecisionFloor(new BN(exchangeXRC20Balance).div(10 ** decimals).toFixed());

    const totalLiquidityFormatted = utils.helper.number.toPrecisionFloor(
      Number(new BN(_exchangeXRC20Balance).div(10 ** decimals)) * Number(priceToIotx) + Number(fromRau(exchangeIotxBalance, "Iotx"))
    );
    Object.assign(this.tokens[token.address], {
      priceToIotx
    });

    Object.assign(this.tokens[token.address].pool, {
      totalSupply,
      totalSupplyFormatted,
      exchangeXRC20Balance,
      exchangeIotxBalance,
      exchangeIotxBalanceFormatted,
      noLiquidity,
      exchangeXRC20BalanceFormatted,
      totalLiquidityFormatted
    } as Partial<TokenTradeMetadata["pool"]>);
  }

  @action.bound
  async loadTokenPoolWithAccount(token: Partial<TokenTradeMetadata>) {
    if (!token.xrc20 || !AntennaUtils.account || !token.exchange || token.pool.noExchange) return;
    const { decimals } = this.tokens[token.address];
    const { exchangeIotxBalance, exchangeXRC20Balance, totalSupply } = this.tokens[token.address].pool;
    let balance = await token.exchange.balanceOf({ address: AntennaUtils.account.address });

    const totalSupplyBN = new BN(totalSupply);
    const share = totalSupplyBN.eq(0) ? new BN(0) : new BN(balance).div(totalSupply);

    const pooledIotx = new BN(exchangeIotxBalance).multipliedBy(share).toFixed();
    const pooledToken = new BN(exchangeXRC20Balance).multipliedBy(share).toFixed();
    const pooledIotxFormatted = utils.helper.number.toPrecisionFloor(fromRau(pooledIotx, "iotx"));
    const pooledTokenFormatted = utils.helper.number.toPrecisionFloor(new BN(pooledToken).div(10 ** decimals).toFixed());
    balance = new BN(balance).toFixed();
    const balanceFormatted = utils.helper.number.toPrecisionFloor(new BN(balance).div(1e18).toFixed());

    Object.assign(this.tokens[token.address].pool, {
      balance,
      balanceFormatted,
      share: share.toFixed(),
      pooledTokenFormatted,
      pooledToken,
      pooledIotxFormatted,
      pooledIotx
    } as Partial<TokenTradeMetadata["pool"]>);
  }

  @action.bound
  async loadTokenXRC20Data(token: Partial<TokenTradeMetadata>) {
    if (!token.xrc20 || (token.symbol && token.decimals)) return;
    const [_decimals] = await Promise.all([token.xrc20.decimals()]);
    const decimals = _decimals.toNumber();
    Object.assign(this.tokens[token.address], {
      decimals
    });
    return this.tokens[token.address];
  }

  @action.bound
  async loadXRC20AccountData(token: Partial<TokenTradeMetadata>) {
    if (!token.xrc20 || !AntennaUtils.account || !token.exchangeAddress) return;
    const { decimals } = this.tokens[token.address];
    const [_balance, _allowance] = await Promise.all([
      token.xrc20.balanceOf(AntennaUtils.account.address),
      token.xrc20.allowance(AntennaUtils.account.address, token.exchangeAddress, { ...AntennaUtils.defaultContractOptions, account: AntennaUtils.account })
    ]);

    // const allowance = 0;
    const allowance = new BN(_allowance).div(10 ** token.decimals).toFixed();
    const balance = new BN(_balance).toFixed();
    const balanceFormatted = utils.helper.number.toPrecisionFloor(new BN(_balance).div(10 ** decimals).toFixed());
    Object.assign(this.tokens[token.address], {
      allowance,
      balance,
      balanceFormatted
    } as Partial<TokenTradeMetadata>);
    return this.tokens[token.address];
  }

  @action.bound
  async selectToken({ address }: { address: string }) {
    if (!address) return;
    if (!this.tokens[address]) {
      if (validateAddress(address)) {
        const xrc20 = new XRC20(address, { provider: AntennaUtils.getAntenna().iotx, signer: AntennaUtils.getAntenna().iotx.signer });
        const [symbol, decimals] = await Promise.all([xrc20.symbol(), xrc20.decimals()]);
        if (!symbol || !decimals) return;
        console.log({ symbol });

        //@ts-ignore
        this.tokens[address] = {
          address,
          xrc20,
          logoURI: utils.helper.img.defaultImgUrl(),
          symbol: symbol.toString(),
          decimals: decimals.toNumber(),
          state: {
            loading: false,
            external: true,
            saved: false
          },
          pool: {}
        };
      }
    }
    const token = this.tokens[address];

    if (address == "iotx") {
      this.tokens[address] = { ...token, allowance: rootStore.wallet.account.balance, balance: rootStore.wallet.account.balance, symbol: "IOTX", decimals: 18, priceToIotx: "1" };
    } else {
      await this.loadToken(token);
    }
  }

  @action.bound
  async batchLoadXRC20AccountData() {
    await Promise.all(
      Object.values(this.tokens).map(async i => {
        if (i.balance || utils.helper.tokenData.checkIsIotx(i)) return;
        i.state.loading = true;
        return this.loadExchange(i)
          .then(() => this.loadTokenXRC20Data(i))
          .then(() => this.loadXRC20AccountData(i))
          .finally(() => (i.state.loading = false));
      })
    );
  }

  @action.bound
  async loadToken(token: Partial<TokenTradeMetadata>) {
    if (utils.helper.tokenData.checkIsIotx(token)) return;
    token.state.loading = true;
    await this.loadExchange(token);
    await this.loadTokenXRC20Data(token);
    await this.loadTokenPool(token);
    await this.loadXRC20AccountData(token);
    await this.loadTokenPoolWithAccount(token);
    token.state.loading = false;
  }

  @action.bound
  async loadExchange(token: Partial<TokenTradeMetadata>) {
    if (validateAddress(token.exchangeAddress) && token.exchangeAddress !== "io1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqd39ym7") {
      if (token.exchangeAddress) {
        token.exchange = new ExchangeContract({ contractAddress: token.exchangeAddress });
      }
    } else if (validateAddress(token.address)) {
      const exchangeAddress = await contracts.factoryContract.getExchange(token.address);
      if (exchangeAddress) {
        token.exchangeAddress = exchangeAddress;
        token.exchange = new ExchangeContract({ contractAddress: exchangeAddress });
        if (!utils.helper.exchange.isValidExchange(exchangeAddress)) {
          token.pool.noExchange = true;
        }
      }
    }
  }

  @observable isCheckingAction = false;
  @action.bound
  async checkActionHistory() {
    if (this.isCheckingAction) return;
    this.isCheckingAction = true;
    const queue = new Queue({ concurrency: 1 });
    this.actionHistory
      .filter(i => i.status == "init")
      .map(i =>
        queue.add(async () => {
          try {
            const receipt = await AntennaUtils.getAntenna().iotx.getReceiptByAction({ actionHash: i.actionHash });
            console.log({ receipt });
            if (receipt.receiptInfo.receipt.status == 1) {
              this.setActionData({ actionHash: i.actionHash, data: { status: "success", confirmedTime: Date.now(), receipt } });
              const actionHistory = this.actions[publicConfig.IOTEX_CORE_ENDPOPINT][i.actionHash];
              eventBus.emit("client.action.success", actionHistory);
            }
            if ([106].includes(receipt.receiptInfo.receipt.status)) {
              this.setActionData({ actionHash: i.actionHash, data: { status: "failed", confirmedTime: Date.now(), receipt } });
            }
          } catch (e) {
            if (Date.now() - i.addedTime > this.checkHistoryInterval * 3) {
              this.setActionData({ actionHash: i.actionHash, data: { status: "failed", confirmedTime: Date.now() } });
            }
          }
        })
      );
    await queue.onIdle();
    this.isCheckingAction = false;
    this.storeActions();
  }

  @action.bound
  setActionData({ actionHash, data }: { actionHash: string; data: Partial<ActionHistory> }) {
    if (!this.actions[publicConfig.IOTEX_CORE_ENDPOPINT]) {
      this.actions[publicConfig.IOTEX_CORE_ENDPOPINT] = {};
    }
    //@ts-ignore
    const target = this.actions[publicConfig.IOTEX_CORE_ENDPOPINT][actionHash];
    this.actions[publicConfig.IOTEX_CORE_ENDPOPINT][actionHash] = {
      ...target,
      ...data
    };
    this.storeActions();
  }

  @action.bound
  async approve({ token, amount }: { token: string; amount: string }) {
    const antenna = AntennaUtils.getAntenna();
    const metadata = this.tokens[token];
    const xrc20 = new XRC20(metadata.address, { provider: antenna.iotx, signer: antenna.iotx.signer });
    const approveAmount = rootStore.setting.data.approveAll ? new BN(1.157920892373162e59) : new BN(amount).multipliedBy(10 ** metadata.decimals);
    //@ts-ignore
    console.log("approve", { address: metadata.exchangeAddress, approveAmount: approveAmount.toFixed() });
    const actionHash = await xrc20.approve(metadata.exchangeAddress, approveAmount, { ...AntennaUtils.defaultContractOptions, account: antenna.iotx.accounts[0] });

    metadata.allowance = amount;

    // store actionhash for check receipt later
    this.setActionData({
      actionHash,
      data: {
        actionHash,
        status: "init",
        type: "approve",
        source: {
          token
        },
        summary: `Approve ${rootStore.setting.data.userExpertMode ? "Maximum" : amount} ${metadata.symbol}`,
        approval: {
          tokenAddress: token,
          spender: metadata.exchangeAddress
        },
        from: antenna.iotx.accounts[0].address,
        addedTime: Date.now()
      }
    });
  }
}
