import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Button, Divider, Tooltip, Avatar, Image, Modal } from "antd";
import { ArrowDownOutlined, RightOutlined, RiseOutlined } from "@ant-design/icons";
import { TokenInputField } from "../../../../components/TokenInputField";
import { AddressInputField } from "../../../../components/AddressInputField";
import { useStore } from "../../../../../common/store";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { TokenTradeMetadata } from "../../../../../type";
import { SwapConfirmModal } from "../SwapConfirmModal/SwapConfirmModal";
import { utils } from "../../../../../common/utils";
import { SwapSubmittedModal } from "../SwapSubmittedModal/index";
import { useRouter } from "../../../../utils/react-hooks";
import { hooks } from "../../../../utils/hooks";
import { TokenAvatar } from "../../../../components/TokenAvatar/index";
import { toJS } from "mobx";

export const Swap = () => {
  const { lang, token: tokenStore, swap, wallet, setting } = useStore();
  const router = useRouter();

  const store = useLocalStore(() => ({
    onConfirm() {
      if (swap.tradeStatus.ready) {
        if (setting.data.userExpertMode) {
          swap.handleSwap();
        } else {
          swap.status = "confirm";
        }
      }
      if (swap.tradeStatus.connectWallet) {
        wallet.connectWallet();
      }
    },
    async selectToken(key, val: Partial<TokenTradeMetadata>) {
      swap.setToken(key, val.address);
      await tokenStore.selectToken({ address: val.address });
      swap.updateAmount(key);
    },
    async swapTokenInput() {
      const _source = swap.source;
      swap.source = { ...swap.dest };
      swap.dest = { ..._source };
      //@ts-ignore
      swap.updateAmount(swap.input);
      swap.input = swap.input == "source" ? "dest" : "source";
    },
    handleParams({ checkToken }: { checkToken: boolean } = { checkToken: false }) {
      store.selectToken("source", { address: router.query.source || "iotx" });
      if (checkToken) {
        console.log(toJS(tokenStore.tokens));
        if ((router.query.source && !tokenStore.tokens[router.query.source]) || (router.query.dest && !tokenStore.tokens[router.query.dest])) {
          Modal.confirm({
            title: lang.t("warning"),
            content: lang.t("url_token_warning"),
            okText: lang.t("i_understand")
          });
        }
      }
      if (router.query.dest) {
        store.selectToken("dest", { address: router.query.dest });
      }
    },
    get priceText() {
      if (swap.sourceTokenData?.symbol && swap.destTokenData?.symbol && !swap.tradeStatus.hideStat) {
        if (!swap.stats.destSourceRate.isNaN() && swap.stats.destSourceRate.toFixed() === "Infinity") {
          return lang.t("no_liquidity_1");
        }

        if (swap.sourceToDestRate) {
          // if (swap.stats.destSourceRate.isNaN()) {
          //   swap.handleSourceAmount("1");
          // }
          return `${utils.helper.number.toPrecisionFloor(swap.stats.destSourceRate.toFixed())} ${swap.sourceTokenData?.symbol} per ${swap.destTokenData?.symbol}`;
        }
        return `${utils.helper.number.toPrecisionFloor(swap.stats.sourceDestRate.toFixed())} ${swap.destTokenData?.symbol} per ${swap.sourceTokenData?.symbol}`;
      }
      return "";
    }
  }));

  useEffect(() => {
    hooks.waitInit().then(() => store.handleParams({ checkToken: true }));
    hooks.waitAccount().then(() => {
      store.handleParams();
    });
    return () => {
      swap.reest();
    };
  }, []);

  return useObserver(() => (
    <div className="component__swap">
      <TokenInputField
        amount={swap.source.amount}
        token={swap.source.token}
        label={lang.t(swap.input == "source" ? "from" : "from_estimated")}
        onChangeAmount={val => {
          swap.input = "source";
          swap.handleSourceAmount(val);
        }}
        onChangeToken={val => store.selectToken("source", val)}
        loading={swap.sourceTokenData.state?.loading}
        blackList={[swap.dest.token, swap.source.token]}
        showMax
      />
      <div className="component__swap__middle my-2">
        <Button icon={<ArrowDownOutlined />} shape="circle" type="text" className="component__swap__middle__down" onClick={store.swapTokenInput} />
      </div>
      <TokenInputField
        amount={swap.dest.amount}
        token={swap.dest.token}
        label={lang.t(swap.input == "dest" ? "to" : "to_estimated")}
        onChangeAmount={val => {
          swap.input = "dest";
          swap.handleDestAmount(val);
        }}
        onChangeToken={val => store.selectToken("dest", val)}
        loading={swap.destTokenData.state?.loading}
        blackList={[swap.dest.token, swap.source.token]}
        showMax={false}
      />

      {swap.showRecipient && (
        <>
          <div className="flex items-center justify-between component__swap__middle my-2">
            <Button icon={<ArrowDownOutlined />} shape="circle" type="text" className="component__swap__middle__down" />
          </div>
          <AddressInputField label="Recipient" placeholder="Wallet Address" address={swap.recipientAddress} onChange={val => (swap.recipientAddress = val)} />
        </>
      )}
      {swap.sourceTokenData?.symbol && swap.destTokenData?.symbol && !swap.tradeStatus.hideStat && (
        <div className="flex items-center justify-between component__swap__middle mt-2 mb-2 c-gray">
          <span className="text-sm">{lang.t("price")}</span>
          <span className="flex items-center text-base">
            {store.priceText}
            <img className="cursor-pointer w-6 ml-1" src="/image/icon_swap.png" onClick={e => (swap.sourceToDestRate = !swap.sourceToDestRate)} />
          </span>
        </div>
      )}

      {swap.tradeStatus.allownces &&
        swap.tradeStatus.allownces.map(({ token, amount }) => {
          const tokenData = tokenStore.tokens[token];
          return (
            <Button
              key={token}
              onClick={e => tokenStore.approve({ token, amount })}
              type="primary"
              className="page__liquidity_add__content__main__approve component__swap__btn_connect_wallet text-center w-full mt-4"
              size="large"
            >
              {lang.t("approve")} {tokenData.symbol}
            </Button>
          );
        })}

      <Button type="primary" disabled={swap.tradeStatus.error} className="w-full component__swap__btn_connect_wallet mt-4" size="large" onClick={store.onConfirm}>
        {swap.tradeStatus.msg}
      </Button>
      <div className="component__swap__details__special__line rounded bg-white"></div>
      {swap.dest.token && swap.dest.amount && !swap.tradeStatus.hideStat && (
        <div className="component__swap__details mb-4 c-gray-10 text-sm">
          <div className="p-5">
            <div className="flex items-center justify-between mt-2">
              <span className="flex items-center">
                {swap.input == "source" ? lang.t("minimum_received") : lang.t("maximun_sold")}&nbsp;
                <Tooltip title={lang.t("swap.helper.comment")} trigger={["hover", "click"]}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
              <span>
                <span>{swap.stats.minimunRecceived.formatted}</span>
                <span className="ml-1">{swap.input == "source" ? swap.destTokenData.symbol : swap.sourceTokenData.symbol}</span>
              </span>
            </div>
            <div className="flex items-center justify-between mt-2">
              <span className="flex items-center">
                {lang.t("price_impact")}&nbsp;
                <Tooltip title={lang.t("swap.helper.price_impact")} trigger={["hover", "click"]}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
              <span className={`${Number(swap.stats.priceImpace) <= 3 ? "c-third" : "text-red-600"}`}>{`${swap.stats.priceImpace} %`}</span>
            </div>
            <div className="flex items-center justify-between mt-2">
              <span className="flex items-center">
                {lang.t("liquidity_provider_fee")}&nbsp;
                <Tooltip title={lang.t("swap.helper.liquidity_provider_fee")} trigger={["hover", "click"]}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
              <span className="text-right">
                {swap.stats.liquidityProviderFee.toFixed(6)} {swap.sourceTokenData.symbol}
              </span>
            </div>
            <Button
              type="primary"
              target="_blank"
              style={{ color: "var(--bg-third)", borderColor: "var(--bg-third)" }}
              href={`https://v1.info.mimo.exchange/pairs/${swap.destTokenData.address}`}
              ghost
              block
              className="mt-4"
              size="small"
            >
              <div className="flex items-center justify-center">
                <span className="mr-2">{lang.t("swap.helper.view.pair")}</span> <RiseOutlined />
              </div>
            </Button>
          </div>
          {swap.stats.tradeFlow == "token->token" && (
            <div>
              <Divider style={{ margin: "10px 0 0" }} />
              <div className="p-5">
                <div className="flex items-center">
                  {lang.t("route")}&nbsp;
                  <Tooltip title={lang.t("swap.helper.route")} trigger={["hover", "click"]}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <span className="c-black flex items-center">
                    <TokenAvatar src={swap.sourceTokenData?.logoURI} size="default" />
                    <b className="ml-1 text-lg">{swap.sourceTokenData?.symbol}</b>
                  </span>
                  <RightOutlined />
                  <span className="c-black flex items-center">
                    <TokenAvatar src={tokenStore.tokens["iotx"].logoURI} size="default" />
                    <b className="ml-1 text-lg">{tokenStore.tokens["iotx"].symbol}</b>
                  </span>
                  <RightOutlined />

                  <span className="c-black flex items-center">
                    <TokenAvatar src={swap.destTokenData?.logoURI} size="default" />
                    <b className="ml-1 text-lg">{swap.destTokenData?.symbol}</b>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <SwapConfirmModal />
      <SwapSubmittedModal />
    </div>
  ));
};
