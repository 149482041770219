import React, { MouseEventHandler } from "react";
import { Modal, Button, Input, Divider, Avatar, Image } from "antd";
import { useStore } from "../../../../../common/store";
import { useObserver } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { TradingPairIcon } from "../../../../components/TradingPairIcon/index";
import { utils } from "../../../../../common/utils/index";
import { TokenAvatar } from "../../../../components/TokenAvatar/index";

interface IComponentProps {}

export const AddLiquidityConfirmModal = (props: IComponentProps) => {
  const { lang, addLiquidity } = useStore();

  // TODO update hard-coded numbers
  return useObserver(() => (
    <Modal
      visible={["confirm", "waiting"].includes(addLiquidity.status)}
      className="modal__add_liquidity_confirm"
      onCancel={(e) => (addLiquidity.status = "init")}
      width={415}
      title={<span className="c-gray-10">{lang.t("you_will_receive")}</span>}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      {addLiquidity.status == "waiting" && (
        <div className="px-4 pb-4 text-center">
          <LoadingOutlined className="c-third text-5xl font-light" />
          <div className="c-black text-2xl mt-5 mb-4">{lang.t("swap.waiting_for_confimration")}</div>
          <div className="c-gray text-lg mb-10">
            {lang.t("add_liquidity.supply_comment", {
              sourceAmount: addLiquidity.sourceTokenData.amountFormatted,
              sourceToken: addLiquidity.sourceTokenData?.symbol,
              destAmount: addLiquidity.destTokenData.amountFormatted,
              destToken: addLiquidity.destTokenData?.symbol,
            })}
          </div>
          <div className="c-gray-10 text-sm mb-5">{lang.t("swap.confirm_transaction")}</div>
        </div>
      )}
      {addLiquidity.status == "confirm" && (
        <>
          <div className="px-4">
            <div className="flex items-center justify-between  text-3xl">
              <span className="c-gray-30">{addLiquidity.stats.liquidity_minted}</span>
              <TradingPairIcon tokenIcon={addLiquidity.destTokenData?.logoURI} otherIcon={addLiquidity.sourceTokenData?.logoURI} />
            </div>
            <div className="text-lg mt-0 mb-5 c-gray">{`${addLiquidity.destTokenData?.symbol}/${addLiquidity.sourceTokenData?.symbol} Pool Tokens`}</div>
            <div className="font-light text-sm mb-4 c-gray">Output is estimated. If the price changes by more than 0.5% your transaction will revert</div>
          </div>
          <div className="bg-white-30 p-4">
            <div>You are going to deposit</div>
            <div className="p-2 mb-4 text-base font-light">
              <div className="flex justify-between items-center c-gray mb-2">
                <span></span>
                <div className="flex items-center">
                  <span className="mr-2">{addLiquidity.sourceTokenData.amountFormatted}</span>
                  <span className="mr-2">{addLiquidity.sourceTokenData.symbol}</span>
                  <TokenAvatar className="avatar-width ml-2" src={addLiquidity.sourceTokenData?.logoURI} />
                </div>
              </div>
              <div className="flex justify-between items-center c-gray mb-2">
                <span></span>
                <div className="flex items-center">
                  <span className="mr-2">{addLiquidity.destTokenData.amountFormatted}</span>
                  <span className="mr-2">{addLiquidity.destTokenData.symbol}</span>
                  <TokenAvatar className="avatar-width" src={addLiquidity.destTokenData?.logoURI} />
                </div>
              </div>
              <div className="flex justify-between items-center c-gray mb-2">
                <span>{lang.t("rates")}</span>

                <span>
                  1 {addLiquidity.sourceTokenData?.symbol} = {addLiquidity.stats.sourceDestRate} {addLiquidity.destTokenData?.symbol}
                </span>
              </div>
              <div className="c-grey text-right mb-2">
                1 {addLiquidity.destTokenData?.symbol} = {addLiquidity.stats.destSourceRate} {addLiquidity.sourceTokenData?.symbol}
              </div>
              <div className="c-grey text-right mb-2 flex justify-between items-center">
                <span>{lang.t("share_of_pool")}</span>
                <span>{addLiquidity.stats.shareOfPool}%</span>
              </div>
            </div>

            <Button type="primary" className="text-center w-full" size="large" onClick={() => addLiquidity.handleSupply()}>
              {addLiquidity.tradeStatus.noLiquidity ? lang.t("confirm_create_pool") : lang.t("confirm_supply")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  ));
};
