import React from "react";
import "./index.scss";
import { useStore } from "../../../common/store";
import { Button, Spin } from "antd";
import { SettingsPopover } from "../SettingsPopover";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { utils } from "../../../common/utils/index";
import { AccountChangeModal } from "../AccountChangeModal";
import { LoadingOutlined } from "@ant-design/icons";
import { useRouter } from "../../utils/react-hooks";
import { MorePopover } from "../MorePopover/more-popover.component";

export const AppHeader = () => {
  const { lang, wallet, token } = useStore();
  const router = useRouter();

  const store = useLocalStore(() => ({
    onMore() {},
    onSettings() {},
    onConnectWallet() {
      wallet.connectWallet();
    },
    goHome() {
      router.history.replace("/");
    },
  }));

  const renderAccount = () => {
    if (wallet.account.address) {
      if (token.pendingActions.length) {
        return (
          <div className="rounded-lg px-3 py-1 c-white bg-third flex items-center font-light" onClick={wallet.toggleAccountChangeModal}>
            <span className="mr-2">
              {token.pendingActions.length} {lang.t("pending")}
            </span>
            <Spin indicator={<LoadingOutlined className="c-white" />} />
          </div>
        );
      }
      return (
        <>
          {wallet.account.balance && <span className="hide-on-mobile">{wallet.state.balance} IOTX</span>}&nbsp;
          <div onClick={wallet.toggleAccountChangeModal} className="cursor-pointer">
            {utils.helper.string.truncate(wallet.account.address, 12)}
          </div>
          &nbsp;&nbsp;
          <img src="/image/iotx.png" className="w-8" />
        </>
      );
    }
    return (
      <button className="component__header__content__right__wallet_connect" onClick={store.onConnectWallet}>
        {lang.t("header.connect_to_wallet")}
      </button>
    );
  };
  return useObserver(() => (
    <div className="component__header h-12 sm:h-10 md:h-12 lg:h-16">
      <div className="component__header__content px-0 lg:px-0 w-full lg:w-11/12 flex justify-between items-center m-auto py-2 sm:py-2 md:py-3 lg:py-3">
        <img alt="logo" className="component__header__content__logo cursor-pointer" src={"/image/logo.png"} onClick={store.goHome} />
        <div className="component__header__content__right c-gray flex justify-between items-center text-lg">
          {renderAccount()}
          <SettingsPopover>
            <button className="component__header__content__right__icon_button ml-2" onClick={store.onSettings} />
          </SettingsPopover>
          <MorePopover>
            <button className="dynamic__icon--wrapper ml-2" onClick={store.onMore} />
          </MorePopover>
        </div>
      </div>
      {wallet.account.address && <AccountChangeModal />}
    </div>
  ));
};
