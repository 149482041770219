import React from "react";
import { Modal, Button } from "antd";
import { useObserver } from "mobx-react-lite";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useLocalStore } from "mobx-react";
import { useStore } from "../../../../../common/store/index";
import { publicConfig } from "../../../../../../configs/public";

interface IComponentProps {}

export const RemoveLiquiditySubmittedModal = (props: IComponentProps) => {
  const { lang, removeLiquidity } = useStore();
  const store = useLocalStore(() => ({
    onOK() {
      removeLiquidity.status = "init";
    },
  }));

  return useObserver(() => (
    <Modal
      visible={removeLiquidity.status == "done"}
      className="modal__transaction_submitted"
      onCancel={store.onOK}
      width={415}
      title={<span className="c-gray-10">{lang.t("you_will_receive")}</span>}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <div className="px-4 pb-4 text-center">
        <ArrowUpOutlined className="c-third text-3xl border-third modal__transaction_submitted__arrow p-2" />
        <div className="text-2xl c-gray-30 mt-4 font-semibold">{lang.t("transaction_submitted")}</div>
        <a className="cursor-pointer" href={`${publicConfig.IOTEXSCAN_ENDPOINT}/action/${removeLiquidity.pendingAction.actionHash}`} target="_blank">
          <div className="text-lg c-gray-20 mt-4 mb-6">{lang.t("view_on_iotexscan")}</div>
        </a>
        <Button type="primary" className="text-center w-full" size="large" onClick={store.onOK}>
          {lang.t("ok")}
        </Button>
      </div>
    </Modal>
  ));
};
