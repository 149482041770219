import React, { useState } from "react";
import "./index.scss";
import { Input, Button, Divider, Image } from "antd";
import { useStore } from "../../../common/store";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { TokenSelectModal } from "../TokenSelectModal";
import Avatar from "antd/lib/avatar/avatar";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { TokenMetadata, TokenTradeMetadata } from "../../../type";
import { utils } from "../../../common/utils/index";
import { TokenAvatar } from "../TokenAvatar/index";

interface IComponentProps {
  token: null | TokenTradeMetadata;
  onChangeToken: Function;
  showCommonBases?: boolean;
  loading?: boolean;
}

export const TokenSelectField = (props: IComponentProps) => {
  const { token, onChangeToken, showCommonBases } = props;
  const { lang, wallet, token: tokenStore } = useStore();

  const store = useLocalStore(
    (props) => ({
      modalOpen: false,
      toggleModal() {
        this.modalOpen = !this.modalOpen;
      },
    }),
    props
  );

  return useObserver(() => (
    <div className="component__token_select_field my-2 bg-white-30 border-none rounded-lg py-4 px-4">
      <div className="c-gray-30 flex justify-between items-center text-base font-semibold cursor-pointer" onClick={store.toggleModal}>
        <div>
          {token ? (
            <>
              <TokenAvatar src={token.logoURI} className="app-avatar" />
              <span className="ml-2">{token.symbol}</span>
            </>
          ) : (
            lang.t("select_a_token")
          )}
        </div>
        <div>
          <DownOutlined />
        </div>
      </div>

      <TokenSelectModal modalOpen={store.modalOpen} toggleModal={store.toggleModal} onChange={onChangeToken} showCommonBases={showCommonBases || false} value={token?.address} />
    </div>
  ));
};
