import { utils } from "../src/common/utils/index";

export const tokenMetadata = {
  testnet: [
    {
      symbol: "IOTX",
      label: "iotx",
      address: "iotx",
      logoURI: "/image/iotx.png"
    },
    {
      symbol: "ioETH",
      label: "Wrapped ETH",
      address: "io1jw6eckew7wak75j7vzxldd5ma88n9pxkajecrw",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      symbol: "ioBUSD",
      label: "Binance USD",
      address: "io1nth44knlehx5nna8ezdpeej5clnj25rgcr7slu",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      symbol: "ioPAXG",
      label: "Paxos Gold",
      address: "io1cxd42m8j72nmy0mmruww82vnrgukdzuh0m3wm2",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png"
    },
    {
      symbol: "TEST",
      label: "TEST",
      address: "io1wgt0skt4s3aa8c76z86xhhjs9z07zjarjpw0a8",
      logoURI: "/image/test.png"
    },
    {
      symbol: "TEST1",
      label: "TEST1",
      address: "io105svyzmjwkun3d4jyjek0l3mk9fujc25luuelx",
      logoURI: "/image/test1.png"
    },
    {
      symbol: "8DEC",
      label: "8DEC",
      address: "io1qkzugxvvrzc08hqyycd5c5pug9pja752cg470a"
    }
  ],
  mainnet: [
    {
      symbol: "IOTX",
      label: "iotx",
      address: "iotx",
      logoURI: "/image/iotx.png"
    },
    {
      symbol: "ioETH",
      label: "Wrapped ETH",
      address: "io1qfvgvmk6lpxkpqwlzanqx4atyzs86ryqjnfuad",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      symbol: "ioBUSD",
      label: "Binance USD",
      address: "io14nhfkywdfvl40evgsqnh43ev33q6he8yez8c8a",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      symbol: "VITA",
      label: "VITA",
      address: "io1hp6y4eqr90j7tmul4w2wa8pm7wx462hq0mg4tw",
      logoURI: "/image/vita.png"
    },
    {
      symbol: "ioUNI",
      label: "UNISWAP",
      address: "io1ahh04jn2zkqlug6feh7rpq75a75p3rj42hp4ch",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      symbol: "ioPAXG",
      label: "Paxos Gold",
      address: "io19fsq8e9krrlng4ay5gyq6q5tqfym28yq9ly0fz",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png"
    },
    {
      symbol: "METX",
      label: "Metanyx",
      address: "io1gafy2msqmmmqyhrhk4dg3ghc59cplyhekyyu26"
    },
    {
      symbol: "ioWBTC",
      label: "Wrapped Bitcoin",
      address: "io1c7unwg8h8vph89xwqru4f7zfa4yy5002wxvlrm"
    },
    {
      symbol: "CYC",
      label: "Cyclone Protocol",
      address: "io1f4acssp65t6s90egjkzpvrdsrjjyysnvxgqjrh"
    },
    {
      symbol: "SDI",
      label: "SonicDiamond",
      address: "io13z9y0kqcgsmke00xwdalcup2zz3pwhuhexd22e"
    },
    {
      symbol: "GFT",
      label: "GameFantasyToken",
      address: "io1zl0el07pek4sly8dmscccnm0etd8xr8j02t4y7"
    }
    // {
    //   symbol: "ioUSDT",
    //   label: "Tether USD",
    //   address: "io1er3t54p96l6x70l9hmd6nmx7sm74jc9sz287m6",
    // },
  ]
};
export const wiotxTokenMetadata = {
  testnet: {
    symbol: "WIOTX",
    label: "wiotx",
    address: "io1kwxuxch2y67hdaqp69pfrtuapp7xdxkkxyejr5",
    logoURI: "/image/wiotx.png"
  },
  mainnet: {
    symbol: "WIOTX",
    label: "wiotx",
    address: "io15qr5fzpxsnp7garl4m7k355rafzqn8grrm0grz",
    logoURI: "/image/wiotx.png"
  }
};
