import React, { Fragment } from "react";
import "./index.scss";
import Input from "antd/lib/input";
import { useStore } from "../../../common/store";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { TokenSelectModal } from "../TokenSelectModal";
import Avatar from "antd/lib/avatar/avatar";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { utils } from "../../../common/utils/index";
import { Image } from "antd";
import { TokenAvatar } from "../TokenAvatar/index";
import { TokenTradeMetadata } from "../../../type";

interface IComponentProps {
  label: string;
  amount: number | string;
  token: string;
  onChangeAmount: Function;
  onChangeToken: Function;
  showCommonBases?: boolean;
  loading?: boolean;
  showMax: boolean;
  selectable?: boolean;
  blackList?: string[];
  wiotx?: TokenTradeMetadata;
}

export const TokenInputField = (props: IComponentProps) => {
  const { label, amount, token, wiotx, onChangeAmount, onChangeToken, showCommonBases, selectable = true, blackList = [] } = props;
  const { lang, wallet, token: tokenStore } = useStore();

  const store = useLocalStore(
    (props) => ({
      modalOpen: false,
      get tokenData() {
        return tokenStore.tokens[props.token];
      },
      toggleModal() {
        if (!selectable) return false;
        this.modalOpen = !this.modalOpen;
      },
    }),
    props
  );

  const renderAddonAfter = () => {
    const isLoading = props.loading || false;
    return (
       <div className="flex items-center bg-transparent">
        {isLoading ? (
          <LoadingOutlined className="text-lg" />
        ) : (
          <>
            {props.showMax && (
              <span
                onClick={() => {
                  props.onChangeAmount(store.tokenData?.symbol == "IOTX" ? Number(wallet.account.balance) - 1.1 : store.tokenData?.balanceFormatted || wiotx?.balanceFormatted);
                }}
                className="c-third bg-green-10 cursor-pointer mx-2 rounded px-2 text-sm"
              >
                {lang.t("max")}
              </span>
            )}
            <span className="component__token_input_field__token_selected c-gray-10 text-lg font-light" onClick={store.toggleModal}>
              {token ? (
                <span className="c-black flex items-center  c-gray-30">
                  <TokenAvatar src={store.tokenData?.logoURI || wiotx?.logoURI} size="small" key={store.tokenData?.address || wiotx?.address} />
                  &nbsp;<b>{store.tokenData?.symbol || wiotx?.symbol}</b>
                </span>
              ) : (
                lang.t("token_input.select_a_token")
              )}
              &nbsp;
              {selectable && <DownOutlined />}
            </span>
          </>
        )}
      </div>
    );
  };

  return useObserver(() => {
    return (
      <div className="component__token_input_field my-2 bg-white-30 border-none rounded py-3 px-4">
        <div className="c-gray-10 flex justify-between items-start font-light text-sm">
          <span className="c-gray-20 flex-1">{label}</span>
          {store.tokenData?.symbol == "IOTX" && wallet.account.address && (
            <div>
              <span>
                {lang.t("balance")}: {utils.helper.number.toPrecisionFloor(wallet.account.balance, { format: "0,0.[0000000000]" })}
              </span>
            </div>
          )}
          {store.tokenData?.symbol !== "IOTX" && store.tokenData?.balance && (
            <span>
              {lang.t("balance")}: {utils.helper.number.toPrecisionFloor(store.tokenData?.balanceFormatted, { format: "0,0.[0000000000]" })}
            </span>
          )}
          {store.tokenData?.symbol !== "IOTX" && wiotx?.balance && (<span>
              {lang.t("balance")}: {utils.helper.number.toPrecisionFloor(wiotx.balanceFormatted, { format: "0,0.[0000000000]" })}
            </span>
          )}
        </div>
        <Input
          className="mt-2 c-grey1 font-medium"
          value={Number(amount) >= 0 ? amount : ""}
          type="number"
          addonAfter={renderAddonAfter()}
          onChange={(event) => {
            onChangeAmount(event.target.value);
          }}
          placeholder="0.0"
        />
        {selectable &&
        <TokenSelectModal blackList={blackList} modalOpen={store.modalOpen} toggleModal={store.toggleModal}
                          onChange={onChangeToken} showCommonBases={showCommonBases || false} value={token}/>
        }
      </div>
    );
  });
};
