import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import { TokenInputField } from "../../../../components/TokenInputField";
import { useStore } from "../../../../../common/store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { hooks } from "../../../../utils/hooks";

export const WIotx = () => {
  const { lang, wiotx, wallet } = useStore();
  const [wiotexToken] = useState(wiotx.initWIotxToken());

  const store = useLocalStore(() => ({
    get priceText() {
      return `1 ${wiotx.dest.token.toUpperCase()} per ${wiotx.source.token.toUpperCase()}`;
    },
    async swapTokenInput() {
      const _source = wiotx.source;
      wiotx.source = { ...wiotx.dest };
      wiotx.dest = { ..._source };
      wiotx.input = wiotx.input == "source" ? "dest" : "source";
    },
    onConfirm() {
      if (wiotx.tradeStatus.ready) {
        wiotx.handleSwap();
      }
      if (wiotx.tradeStatus.connectWallet) {
        wallet.connectWallet();
      }
    }
  }));

  useEffect(() => {
    hooks.waitAccount().then(() => {
      wiotx.refreshWIotxBalance();
    });
    return () => {
      wiotx.rest();
    };
  }, []);

  return useObserver(() => (
    <div className="component__swap">
      <TokenInputField
        amount={wiotx.source.amount}
        token={wiotx.source.token}
        label={lang.t("from")}
        onChangeAmount={val => {
          wiotx.input = "source";
          wiotx.updateAmount(val);
        }}
        wiotx={wiotexToken}
        selectable={false}
        onChangeToken={null}
        loading={false}
        showMax
      />
      <div className="component__swap__middle my-2">
        <Button icon={<ArrowDownOutlined />} shape="circle" type="text" className="component__swap__middle__down" onClick={store.swapTokenInput} />
      </div>
      <TokenInputField
        amount={wiotx.dest.amount}
        token={wiotx.dest.token}
        label={lang.t("to")}
        onChangeAmount={val => {
          wiotx.input = "dest";
          wiotx.updateAmount(val);
        }}
        wiotx={wiotexToken}
        selectable={false}
        onChangeToken={null}
        loading={wiotx.dest.token == "wiotx" ? wiotx.wiotxLoading : false}
        showMax={false}
      />

      <div className="flex items-center justify-between component__swap__middle mt-2 mb-2 c-gray">
        <span className="text-sm">{lang.t("price")}</span>
        <span className="flex items-center text-base">{store.priceText}</span>
      </div>

      <Button type="primary" disabled={wiotx.tradeStatus.error} className="w-full component__swap__btn_connect_wallet mt-4" size="large" onClick={store.onConfirm}>
        {wiotx.tradeStatus.msg}
      </Button>
      <div className="component__swap__details__special__line rounded bg-white" />
    </div>
  ));
};
