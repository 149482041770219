import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, message, Spin, Tooltip } from "antd";
import { useObserver } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../common/store";
import { utils } from "../../../../../common/utils";
import { hooks } from "../../../../utils/hooks";
import { LiquidityItem } from "../LiquidityItem";
import { PoolInformationModal } from "../PoolInformationModal/index";
import "./index.scss";

export const Pool = () => {
  const history = useHistory();
  const { lang, token, wallet } = useStore();

  const onAddLiquidity = () => {
    message.destroy();
    if (wallet.account.address) {
      history.push("/add-liquidity");
    } else {
      message.config({ maxCount: 1 });
      message.warning(lang.t(utils.env.isIoPayMobile() ? "tips.connect_fail.mobile" : "tips.connect_fail"), 1);
    }
  };

  useEffect(() => {
    hooks.waitAccount().then(() => {
      token.loadPool();
    });
  }, []);

  return useObserver(() => (
    <div className="component__pool">
      <div className="flex justify-between items-center c-gray-20 text-sm mb-2 mt-3">
        <span>{lang.t("your_liquidity")}</span>
        <Tooltip placement="leftTop" title={lang.t("your_liquidity_help")}>
          <QuestionCircleOutlined className="c-gray-10" />
        </Tooltip>
      </div>
      <div>
        {token.isLoadingPool ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : token.tokensWithLiquidity.length == 0 ? (
          <div className="bg-white-30 p-6 text-center">{lang.t("no_liquidity")}</div>
        ) : (
          token.tokensWithLiquidity.map((token, index) => <LiquidityItem tokenMeta={token} key={`${index}`} />)
        )}
      </div>
      <Button className="component__pool__add_liquidity w-full mt-6" type="primary" size="large" onClick={onAddLiquidity}>
        {lang.t("add_liquidity")}
      </Button>
      <PoolInformationModal />
    </div>
  ));
};
