import { ExchangeABI } from "../constants/abi";
import { AntennaUtils } from "../utils/antanna";
import { IContract } from "./iContract";
import Antenna from "iotex-antenna";
import { toRau } from "iotex-antenna/lib/account/utils";
import BN from "bignumber.js";

export class ExchangeContract {
  contract: IContract;
  antenna: Antenna;
  constructor({ contractAddress }: { contractAddress: string }) {
    this.antenna = AntennaUtils.getAntenna();
    this.contract = new IContract(ExchangeABI, contractAddress, { provider: this.antenna.iotx, signer: this.antenna.iotx.signer });
  }

  async iotxToTokenTransferInput({ min_tokens, deadline, recipient, amount }: { min_tokens: number; deadline: number; recipient: string; amount: string }) {
    return this.contract.methods.iotxToTokenTransferInput(min_tokens, deadline, recipient, {
      account: this.antenna.iotx.accounts[0],
      amount: toRau(amount, "iotx"),
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async iotxToTokenSwapInput({ min_tokens, deadline, amount }: { min_tokens: string; deadline: number; amount: string }) {
    console.log("iotxToTokenSwapInput", { min_tokens, deadline, amount });

    return this.contract.methods.iotxToTokenSwapInput(min_tokens, deadline, {
      account: this.antenna.iotx.accounts[0],
      amount,
      ...AntennaUtils.defaultContractOptions,
    });
  }
  async iotxToTokenSwapOutput({ tokens_bought, deadline, amount }: { tokens_bought: string; deadline: number; amount: string }) {
    console.log("iotxToTokenSwapOutput", { tokens_bought, deadline, amount });

    return this.contract.methods.iotxToTokenSwapInput(tokens_bought, deadline, {
      account: this.antenna.iotx.accounts[0],
      amount,
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async tokenToIotxTransferInput({ tokens_sold, min_iotx, deadline, recipient }: { tokens_sold: number; min_iotx: number; deadline: number; recipient: string }) {
    return this.contract.methods.iotxToTokenTransferInput(tokens_sold, min_iotx, deadline, recipient, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async tokenToIotxSwapInput({ tokens_sold, min_iotx, deadline }: { tokens_sold: string; min_iotx: string; deadline: number }) {
    console.log("tokenToIotxSwapInput", { tokens_sold, min_iotx, deadline });

    return this.contract.methods.tokenToIotxSwapInput(tokens_sold, min_iotx, deadline, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async tokenToIotxSwapOutput({ iotx_bought, max_tokens, deadline }: { iotx_bought: string; max_tokens: string; deadline: number }) {
    console.log("tokenToIotxSwapOutput", { iotx_bought, max_tokens, deadline });

    return this.contract.methods.tokenToIotxSwapOutput(iotx_bought, max_tokens, deadline, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async tokenToTokenSwapInput({
    tokens_sold,
    min_tokens_bought,
    min_iotx_bought,
    deadline,
    token_addr,
  }: {
    tokens_sold: string;
    min_tokens_bought: string;
    min_iotx_bought: string;
    deadline: number;
    token_addr: string;
  }) {
    console.log("tokenToTokenSwapInput", { tokens_sold, min_tokens_bought, min_iotx_bought, deadline, token_addr });
    return this.contract.methods.tokenToTokenSwapInput(tokens_sold, min_tokens_bought, min_iotx_bought, deadline, token_addr, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async tokenToTokenSwapOutput({
    tokens_bought,
    max_tokens_sold,
    max_iotx_sold,
    deadline,
    token_addr,
  }: {
    tokens_bought: string;
    max_tokens_sold: string;
    max_iotx_sold: string;
    deadline: number;
    token_addr: string;
  }) {
    console.log("tokenToTokenSwapOutput", { tokens_bought, max_tokens_sold, max_iotx_sold, deadline, token_addr });
    return this.contract.methods.tokenToTokenSwapOutput(tokens_bought, max_tokens_sold, max_iotx_sold, deadline, token_addr, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async tokenToTokenTransferInput({
    tokens_sold,
    min_tokens_bought,
    min_iotx_bought,
    deadline,
    recipient,
    token_addr,
  }: {
    tokens_sold: string;
    min_tokens_bought: string;
    min_iotx_bought: string;
    deadline: number;
    recipient: string;
    token_addr: string;
  }) {
    return this.contract.methods.tokenToTokenTransferInput(tokens_sold, min_tokens_bought, min_iotx_bought, deadline, recipient, token_addr, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async removeLiquidity({ amount, min_iotx, min_tokens_bought, deadline }: { amount: string; min_iotx: string; min_tokens_bought: string; deadline: number }) {
    console.log("removeLiquidity", { amount, min_iotx, min_tokens_bought, deadline });
    return this.contract.methods.removeLiquidity(amount, min_iotx, min_tokens_bought, deadline, {
      account: this.antenna.iotx.accounts[0],
      ...AntennaUtils.defaultContractOptions,
    });
  }

  async getIotxToTokenInputPrice({ iotx_sold }: { iotx_sold: string }) {
    const res = await this.contract.readContract({ method: "getIotxToTokenInputPrice", args: [iotx_sold], from: this.contract.getAddress() });
    return res.toString();
  }

  async getTokenToIotxInputPrice({ tokens_sold }: { tokens_sold: string }) {
    const res = await this.contract.readContract({ method: "getTokenToIotxInputPrice", args: [tokens_sold], from: this.contract.getAddress() });
    return res.toString();
  }

  // output
  async getIotxToTokenOutputPrice({ tokens_bought }: { tokens_bought: string }) {
    const res = await this.contract.readContract({ method: "getIotxToTokenOutputPrice", args: [tokens_bought], from: this.contract.getAddress() });
    return res.toString();
  }

  async getTokenToIotxOutputPrice({ iotex_bought }: { iotex_bought: string }) {
    const res = await this.contract.readContract({ method: "getTokenToIotxOutputPrice", args: [iotex_bought], from: this.contract.getAddress() });
    return res.toString();
  }

  async balanceOf({ address }: { address: string }) {
    const res = await this.contract.readContract({ method: "balanceOf", args: [address], from: this.contract.getAddress() });
    return res.toString();
  }
  async totalSupply() {
    const res = await this.contract.readContract({ method: "totalSupply", args: [], from: this.contract.getAddress() });
    return res.toString();
  }

  async addLiquidity({ min_liquidity, max_tokens, deadline, amount }: { min_liquidity: string; max_tokens: string; deadline: number; amount: string }) {
    console.log("addLiquidity", { min_liquidity, max_tokens, deadline, amount });
    return this.contract.methods.addLiquidity(min_liquidity, max_tokens, deadline, {
      account: this.antenna.iotx.accounts[0],
      amount: amount,
      ...AntennaUtils.defaultContractOptions,
    });
  }
}
