import React from "react";
import "./index.scss";
import { useObserver } from "mobx-react-lite";
import { TokenAvatar } from "../TokenAvatar/index";

export const TradingPairIcon = (props) => {
  return useObserver(() => (
    <div className="component__trading__pair__icon flex w-16">
      <TokenAvatar className="app-avatar rounded-full token__icon" src={props.tokenIcon} alt="" />
      <img className="app-avatar iotx__icon" src={props.otherIcon ? props.otherIcon : "/image/iotx.png"} alt="token icon" />
    </div>
  ));
};
